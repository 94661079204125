import { useContext, useState, useRef, useEffect, useMemo, useCallback } from 'react';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import { apiBaseUrl } from '../../config/globalVariables';
import DataGrid, {
	Column,
	Grouping,
	GroupPanel,
	Pager,
	Paging,
	FilterPanel,
	HeaderFilter,
	Export,
	Scrolling,
	RemoteOperations,
	ColumnFixing,
	ColumnChooser,
	MasterDetail,
} from 'devextreme-react/data-grid';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '../../utils/showNotification';
import { useAuth } from '../../contexts/auth';
import { UiConfigContext } from '../../contexts/uiConfig';
import { DateBox, SelectBox, Button } from 'devextreme-react';
import { getRealmHeaders, getUserHeaders, getEnvironmentsHeaders } from '../../api/config';
import { MasterOperationsDetailsView } from './masterOperationsDetailsView';
import { LabelMode } from 'devextreme/common';
import './operations.scss';
import DataSource from 'devextreme/data/data_source';

export const DataGridOperations = () => {
	const navigate = useNavigate();
	const { signOut } = useAuth();
	const { uiConfig } = useContext(UiConfigContext);

	const { defaultFromDate, defaultToDate } = useMemo(() => {
		let defaultToDate = new Date();
		defaultToDate.setHours(23, 59, 59);

		let defaultFromDate = new Date(defaultToDate);
		defaultFromDate.setDate(defaultToDate.getDate() - 30);
		defaultFromDate.setHours(0, 0, 0);

		return { defaultFromDate, defaultToDate };
	}, []);

	const [filters, setFilters] = useState({
		from: undefined as Date | undefined,
		to: undefined as Date | undefined,
		operationType: null,
		status: null,
	});
	const [tempFilters, setTempFilters] = useState({
		from: defaultFromDate as Date | undefined,
		to: defaultToDate as Date | undefined,
		operationType: null,
		status: null,
	});

	const buildFilterConditions = (filters: any) => {
		const conditions: string[] = [];

		if (filters.from) {
			conditions.push(`created ge ${filters.from.toISOString()}`);
		}

		if (filters.to) {
			conditions.push(`created le ${filters.to.toISOString()}`);
		}

		if (filters.operationType) {
			conditions.push(`operationType eq '${filters.operationType}'`);
		}

		if (filters.status) {
			conditions.push(`status eq '${filters.status}'`);
		}

		return conditions.length ? conditions.join(' and ') : null;
	};

	const onApplyFilters = () => {
		if (tempFilters.to && tempFilters.from) {
			const maxDays = 31;
			const dateRangeIsValid = tempFilters.to.getTime() - tempFilters.from.getTime() <= maxDays * 24 * 60 * 60 * 1000;

			if (!dateRangeIsValid) {
				showNotification({
					message: 'El rango de fechas no puede ser mayor a 31 días.',
					type: 'error',
					displayTime: 12000,
					position: uiConfig.notificationsPosition,
				});
				return;
			}

			setFilters({
				from: tempFilters.from,
				to: tempFilters.to,
				operationType: tempFilters.operationType,
				status: tempFilters.status,
			});
		}
	};

	const onClearFilters = () => {
		setTempFilters({
			from: defaultFromDate,
			to: defaultToDate,
			operationType: null,
			status: null,
		});
		setFilters({
			from: undefined,
			to: undefined,
			operationType: null,
			status: null,
		});
	};

	const handleError = useCallback(
		(error: any) => {
			if (error.httpStatus === 401) {
				showNotification({
					message: 'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
					type: 'error',
					displayTime: 12000,
					position: uiConfig.notificationsPosition,
				});
				signOut();
			}
			if (error.httpStatus === 403) {
				navigate('/forbidden');
			}
		},
		[navigate, signOut, uiConfig.notificationsPosition]
	);

	const operationTypeSource = {
		store: {
			version: 4,
			type: 'odata',
			key: 'id',
			url: `${apiBaseUrl}operations/type`,
			errorHandler: handleError,
		},
		select: ['id', 'description'],
	};

	const operationStatusSource = {
		store: {
			version: 4,
			type: 'odata',
			key: 'id',
			url: `${apiBaseUrl}operations/status`,
			errorHandler: handleError,
		},
		select: ['id', 'description'],
	};

	const operationsDataSource = useMemo(() => {
		return new DataSource({
			store: {
				version: 4,
				type: 'odata',
				key: 'finnektaCode',
				url: `${apiBaseUrl}operations`,

				beforeSend: function (request: any) {
					const filtersSelected = {
						from: filters.from,
						to: filters.to,
						operationType: filters.operationType,
						status: filters.status,
					};

					const filterConditions = buildFilterConditions(filtersSelected);

					if (filterConditions) {
						request.params.$filter = filterConditions;
					} else {
						request.params.$filter = buildFilterConditions({
							from: defaultFromDate,
							to: defaultToDate,
						});
					}
					request.headers = {
						...getRealmHeaders(),
						...getUserHeaders(),
						...getEnvironmentsHeaders(),
					};
				},
				errorHandler: handleError,
			},
			select: ['finnektaCode', 'created', 'operationType', 'status'],
		});
	}, [filters, defaultFromDate, defaultToDate, handleError]);

	const pageSizes = useRef([5, 7, 10, 25, 50, 100]);
	const [updatedPageSizes, setUpdatedPageSizes] = useState(pageSizes.current);

	useEffect(() => {
		const defaultRowsPerPage = uiConfig.dataGrid.defaultRowsPerPage;
		if (!pageSizes.current.includes(defaultRowsPerPage)) {
			const newPageSizes = [...pageSizes.current, defaultRowsPerPage].sort((a, b) => a - b);
			pageSizes.current = newPageSizes;
			setUpdatedPageSizes(newPageSizes);
		}
	}, [uiConfig]);

	return (
		<div>
			<form className="filter-container">
				<p>Filtrar listado </p>
				<div className="filter-group">
					<div className="filter-item filter-item-date-from">
						<label>Desde</label>
						<DateBox
							type="date"
							labelMode={uiConfig.labelMode as LabelMode}
							displayFormat="dd/MM/yyyy HH:mm"
							applyValueMode="instantly"
							value={tempFilters.from}
							onValueChanged={(e) => {
								const date = new Date(e.value);
								date.setHours(0, 0, 0);
								setTempFilters((prev) => ({
									...prev,
									from: date,
								}));
							}}
						/>
					</div>
					<div className="filter-item filter-item-date-to">
						<label>Hasta</label>
						<DateBox
							type="date"
							labelMode={uiConfig.labelMode as LabelMode}
							displayFormat="dd/MM/yyyy HH:mm"
							applyValueMode="instantly"
							value={tempFilters.to}
							onValueChanged={(e) => {
								const date = new Date(e.value);
								date.setHours(23, 59, 59);
								setTempFilters((prev) => ({
									...prev,
									to: date,
								}));
							}}
						/>
					</div>
					<div className="filter-item filter-item-type">
						<label>Tipo de operación</label>
						<SelectBox
							dataSource={[
								{
									id: 'GET_ACCOUNTS',
									description: 'Consulta de cuenta',
								},
								{
									id: 'GET_ACCOUNT_MOVEMENTS',
									description: 'Consulta de movimientos de cuenta',
								},
								{
									id: 'Pago a proveedores',
									description: 'Pago a proveedores',
								},
							]}
							valueExpr="id"
							displayExpr="description"
							placeholder="Seleccionar"
							showClearButton={true}
							labelMode={uiConfig.labelMode as LabelMode}
							value={tempFilters.operationType}
							onValueChanged={(e) =>
								setTempFilters((prev) => ({
									...prev,
									operationType: e.value,
								}))
							}
						/>
					</div>
					<div className="filter-item filter-item-status">
						<label>Estado</label>
						<SelectBox
							dataSource={[
								{ id: 'PENDING', description: 'Pendiente' },
								{
									id: 'IN_PROGRESS',
									description: 'En proceso',
								},
								{ id: 'COMPLETED', description: 'Finalizado' },
								{ id: 'FAILURE', description: 'Error' },
							]}
							valueExpr="id"
							displayExpr="description"
							placeholder="Seleccionar"
							showClearButton={true}
							labelMode={uiConfig.labelMode as LabelMode}
							value={tempFilters.status}
							onValueChanged={(e) =>
								setTempFilters((prev) => ({
									...prev,
									status: e.value,
								}))
							}
						/>
					</div>
					<div className="filter-item filter-item-button">
						<Button className="finnekta-button-primary-action" text="FILTRAR" onClick={onApplyFilters} />
					</div>
				</div>
			</form>

			<div className="operations-grid-header">
				<h6 className="operations-grid-header title">Operaciones</h6>
				<Button className="operations-grid-header button" text="Limpiar filtros" onClick={onClearFilters} />
			</div>

			<DataGrid
				dataSource={operationsDataSource}
				showBorders={false}
				focusedRowEnabled={false}
				columnAutoWidth={true}
				columnHidingEnabled={true}
				rowAlternationEnabled={true}
				hoverStateEnabled={true}
				allowColumnReordering={true}
				allowColumnResizing={true}
				width="100%"
				className="dx-datagrid-filter-top"
				onRowExpanding={(e: any) => {
					e.component.collapseAll(-1);
				}}
				//onExporting={onExporting}
				columnResizingMode="widget"
			>
				<MasterDetail enabled={true} component={MasterOperationsDetailsView} />
				<Scrolling mode="standard" />
				<GroupPanel visible={false} />
				<FilterPanel visible={false} />
				<HeaderFilter visible={false} />
				<ColumnFixing enabled={false} />
				<ColumnChooser enabled={false} />
				<Grouping autoExpandAll={true} />
				<RemoteOperations filtering={true} paging={true} sorting={true} summary={false} grouping={false} groupPaging={false} />
				<Column dataField={'finnektaCode'} hidingPriority={1} caption={'Identificador'} minWidth={200} />
				<Column
					hidingPriority={5}
					dataField={'operationType'}
					caption={'Tipo de operación'}
					lookup={{
						dataSource: operationTypeSource,
						valueExpr: 'id',
						displayExpr: 'description',
					}}
					minWidth={200}
				/>
				<Column hidingPriority={6} dataField={'created'} caption={'Fecha de operación'} format="dd/MM/yyyy H:mm" minWidth={200} alignment="center" />
				<Column
					hidingPriority={3}
					dataField={'status'}
					caption={'Estado'}
					lookup={{
						dataSource: operationStatusSource,
						valueExpr: 'id',
						displayExpr: 'description',
					}}
					minWidth={200}
					alignment="center"
				/>
				<Pager allowedPageSizes={updatedPageSizes} showPageSizeSelector={true} showNavigationButtons={true} showInfo={true} displayMode="compact" />
				<Paging defaultPageSize={uiConfig.dataGrid.defaultRowsPerPage} />
				<Export enabled={false} />
			</DataGrid>
		</div>
	);
};
