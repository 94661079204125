import React, { useContext, useState, useEffect } from 'react';
import 'devextreme-react/text-area';
import './users.scss';
import { Button, Switch, TabPanel } from 'devextreme-react';
import 'devextreme/data/odata/store';
import DataGrid, {
	Column,
	Grouping,
	GroupPanel,
	Pager,
	Paging,
	FilterPanel,
	HeaderFilter,
	Scrolling,
	ColumnFixing,
	ColumnChooser,
	Editing,
} from 'devextreme-react/data-grid';
import { UiConfigContext } from '../../contexts/uiConfig';
import { getGlobalRoles } from '../../api/roles';
import { getRolesEnvironments, getRolesByUser } from '../../api/environments';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from '../spinner/spinner';
import { showNotification } from '../../utils/showNotification';
import { changeUseRolesByEnvironments } from '../../api/users';
import { defaultShowTimeSToast } from '../../config/globalVariables';
import { createRoot } from 'react-dom/client';
import {
	GlobalRoleData,
	OptionsData,
	RolesData,
	TransformedRoleEnv,
	TransformedRoleData,
	RoleEnvWithStatus,
	RoleWithStatus,
} from '../../types/userResponse';

const UserRolesByEnvironments = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { userData } = location.state;
	const { uiConfig } = useContext(UiConfigContext);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [dataSource, setDataSource] = useState<RolesData>({
		globalRoles: [],
		environmentRoles: [],
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Ejecuta las tres llamadas asíncronas en paralelo
				const [globalRolesResponse, rolesEnvironmentsResponse, rolesByUserResponse] = await Promise.all([
					getGlobalRoles(),
					getRolesEnvironments(),
					getRolesByUser(userData.userValue),
				]);

				// Construye el dataSource mezclando las respuestas
				const mergedData = mergeData(globalRolesResponse, rolesEnvironmentsResponse, rolesByUserResponse.userRoles);
				setDataSource(mergedData);
			} catch (error) {
				console.error('Error al cargar los datos:', error);
			}
		};

		fetchData(); // Llama a la función asíncrona
	}, [userData.userValue]);

	// Función para mezclar los datos de las respuestas
	const mergeData = (globalRoles: GlobalRoleData[], rolesEnvironments: GlobalRoleData[], userRoles: TransformedRoleData): any => {
		// 1. Procesar Roles Globales
		const mergedGlobalRoles = globalRoles.map((globalRole) => {
			return {
				id: globalRole.id,
				name: globalRole.name,
				description: globalRole.description,
				isActive: (userRoles as any).global.roles.includes(globalRole.id), // Compara si el ID está en los roles globales del usuario
			};
		});

		// 2. Procesar Roles por Ambientes
		let mergedEnvironmentRoles: RoleEnvWithStatus[] = [];
		if (userRoles?.environments && userRoles?.global) {
			// El objeto userRoles tiene la estructura esperada
			mergedEnvironmentRoles = (userRoles).environments.map((env: TransformedRoleEnv) => {
				// Filtrar los roles por ambientes para cada ambiente del usuario
				const environmentRoles = rolesEnvironments.map((role) => {
					return {
						id: role.id,
						name: role.name,
						description: role.description,
						isActive: env.roles.includes(role.id), // Compara si el ID está en los roles del ambiente del usuario
					};
				});

				return {
					id: env.code,
					name: env.name,
					roles: environmentRoles,
				};
			});
		}
		// 3. Retornar la estructura combinada
		return {
			globalRoles: mergedGlobalRoles,
			environmentRoles: mergedEnvironmentRoles,
		};
	};

	const renderActionCellTemplate = (container: HTMLElement, options: OptionsData) => {
		container.innerHTML = '';
		const root = createRoot(container);
		const isActive = options.data.isActive;
		root.render(
			<Switch
			value={isActive}
				onValueChanged={(args) => {
					options.setValue(!args.value);
				}}
			/>
		);
	};

	const TabPanelItem = React.memo(({ data }: { data: RoleEnvWithStatus }) => {
		const envName = data.name;

		return (
			<div className="tabpanel-item">
				<DataGrid
					dataSource={(dataSource as any).environmentRoles.find((env: TransformedRoleEnv) => env.name === envName)?.roles}
					showBorders={false}
					focusedRowEnabled={false}
					columnAutoWidth={true}
					columnHidingEnabled={true}
					rowAlternationEnabled={true}
					hoverStateEnabled={true}
					allowColumnReordering={true}
					allowColumnResizing={true}
					className="dx-datagrid-filter-top"
					keyExpr={'id'}
					columnResizingMode="widget"
					columnFixing={{ enabled: true }}
				>
					<Editing mode="cell" allowUpdating={true} />
					<Scrolling mode="standard" />
					<GroupPanel visible={false} />
					<FilterPanel visible={false} />
					<HeaderFilter visible={false} />
					<ColumnFixing enabled={false} />
					<ColumnChooser enabled={false} />
					<Grouping autoExpandAll={true} />
					<Column dataField={'id'} caption={'Id'} visible={false} allowEditing={false} />
					<Column dataField={'name'} caption={'Roles'} allowEditing={false} />
					<Column dataField={'description'} caption={'Descripción'} allowEditing={false} />
					<Column
						dataField={'isActive'}
						caption="Habilitar"
						editCellTemplate={renderActionCellTemplate}
						allowFiltering={false}
						width={150}
					/>
					<Pager allowedPageSizes={'auto'} showPageSizeSelector={true} showNavigationButtons={true} showInfo={true} displayMode="compact" />
					<Paging defaultPageSize={uiConfig.dataGrid.defaultRowsPerPage} />
				</DataGrid>
			</div>
		);
	});

	//Seccion de Envio de Datos
	const handleSubmit = async () => {
		setIsSubmitting(true);

		//Actualiza el dataSource con los cambios antes de enviar
		await new Promise((resolve) => setTimeout(resolve, 0));
		
		// Transformar roles globales
		const globalRoles: string[] = (dataSource.globalRoles as unknown as RoleWithStatus[])
			.filter((role) => role.isActive) // Solo roles activos
			.map((role) => role.id); // Obtener los IDs de roles activos
			
		// Transformar roles por ambientes
		const environmentRoles = dataSource.environmentRoles.map((env: any) => ({
			code: env.id, // `id` actual corresponde al `code` requerido
			name: env.name,
			roles: env.roles
				.filter((role: any) => role.isActive) // Solo roles activos
				.map((role: any) => role.id), // Obtener los IDs de roles activos
		}));

		// Payload final
		const userRoles = {
			global: {
				roles: globalRoles,
			},
			environments: environmentRoles,
		};

		const result = await changeUseRolesByEnvironments(userData.userValue, userRoles);
		if (result.success) {
			showNotification({ message: result.message, type: 'success', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			navigate('/users');
		} else {
			showNotification({ message: result.message, type: 'error', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			setIsSubmitting(false);
		}
	};
	return (
		<>
			<h2 className={'content-block breadcrumb'}>
				<Link to={'/users'}>Usuarios</Link>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">Roles</span>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">{userData.fullName}</span>
			</h2>
			<div className={'content-block'}>
				<div className={'dx-card responsive-paddings animate__animated animate__fadeIn'}>
					<div className="finnetka-card-text-header">Roles Globales</div>
					<DataGrid
						dataSource={(dataSource as any).globalRoles}
						showBorders={false}
						focusedRowEnabled={false}
						columnAutoWidth={true}
						columnHidingEnabled={true}
						rowAlternationEnabled={true}
						hoverStateEnabled={true}
						allowColumnReordering={true}
						allowColumnResizing={true}
						className="dx-datagrid-filter-top"
						keyExpr={'id'}
						columnResizingMode="widget"
						columnFixing={{ enabled: true }}
					>
						<Editing mode="cell" allowUpdating={true} />
						<Scrolling mode="standard" />
						<GroupPanel visible={false} />
						<FilterPanel visible={false} />
						<HeaderFilter visible={false} />
						<ColumnFixing enabled={false} />
						<ColumnChooser enabled={false} />
						<Grouping autoExpandAll={true} />
						<Column dataField={'id'} caption={'Id'} visible={false} allowEditing={false} />
						<Column dataField={'name'} caption={'Roles'} allowEditing={false} />
						<Column dataField={'description'} caption={'Descripción'} allowEditing={false} />
						<Column
							dataField={'isActive'}
							caption="Habilitar"
							editCellTemplate={renderActionCellTemplate}
							allowFiltering={false}
							width={150}
						/>
						<Pager allowedPageSizes={'auto'} showPageSizeSelector={true} showNavigationButtons={true} showInfo={true} displayMode="compact" />
						<Paging defaultPageSize={uiConfig.dataGrid.defaultRowsPerPage} />
					</DataGrid>
					<br />
					<div className="finnetka-card-text-header">Roles por Ambientes</div>
					<div className="widget-container">
						<TabPanel
							animationEnabled={true}
							swipeEnabled={true}
							dataSource={(dataSource as any).environmentRoles}
							itemTitleRender={(data) => data.name}
							tabsPosition={'top'}
							itemComponent={TabPanelItem}
						/>
					</div>
					<div className="button-container">
						<Button className="finnekta-button-primary-action " text="GUARDAR CAMBIOS" onClick={handleSubmit} />
					</div>
				</div>
			</div>
			{isSubmitting && <Spinner />}
		</>
	);
};

export default UserRolesByEnvironments;
