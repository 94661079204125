import { useCallback, useContext, useState, useRef, useEffect, useMemo } from 'react';
import 'devextreme-react/text-area';
import './users.scss';
import { Button, Popup } from 'devextreme-react';
import 'devextreme/data/odata/store';
import { apiBaseUrl } from '../../config/globalVariables';
import DataGrid, {
	Column,
	Grouping,
	GroupPanel,
	Pager,
	Paging,
	FilterRow,
	FilterPanel,
	HeaderFilter,
	Export,
	Scrolling,
	RemoteOperations,
	ColumnFixing,
	ColumnChooser,
	DataGridTypes,
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '../../utils/showNotification';
import { useAuth } from '../../contexts/auth';
import { UiConfigContext } from '../../contexts/uiConfig';
import { getUserPermissions } from '../../config/actions';
import { getRealmHeaders, getUserHeaders } from '../../api/config';
import { ActionCellRenderer } from './userActionsCellRender';
import { statusLookup } from '../../api/status';
import DataSource from 'devextreme/data/data_source';
import { Error, Request } from '../../types';
import { ToolbarPreparingEvent } from 'devextreme/ui/data_grid';

export const DataGridUsers = () => {
	const navigate = useNavigate();
	const { user, signOut } = useAuth();
	const userPermissions = getUserPermissions(user);
	const { uiConfig } = useContext(UiConfigContext);
	const [filterVisible, setFilterVisible] = useState(uiConfig.dataGrid.defaultFilterVisibility);
	const documentTypeLookup = [
		{ value: 'CI', text: 'Cédula de Identidad' },
		{ value: 'RUC', text: 'RUC' },
	];
	const [statusText, setStatusText] = useState('');
	const [confirmationVisible, setConfirmationVisible] = useState(false);
	const [confirmationAction, setConfirmationAction] = useState<(() => void) | null>(null);
	const dataGridRef = useRef<DataGrid>(null);

	const showConfirmation = useCallback((action: () => void, status: string) => {
		setStatusText(status === 'ACTIVE' ? 'inactivar' : 'activar');
		setConfirmationAction(() => action);
		setConfirmationVisible(true);
	}, []);
	const usersDataSource = useMemo(
		() =>
			new DataSource({
				store: {
					version: 4,
					type: 'odata',
					key: 'userId',
					url: `${apiBaseUrl}users`,
					beforeSend: function (request: Request) {
						request.headers = {
							...getRealmHeaders(),
							...getUserHeaders(),
						};
					},
					errorHandler: function (error: Error) {
						if (error.httpStatus === 401) {
							showNotification({
								message: 'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
								type: 'error',
								displayTime: 12000,
								position: uiConfig.notificationsPosition,
							});
							signOut();
						}
						if (error.httpStatus === 403) {
							navigate('/forbidden');
						}
					},
				},
				select: ['userId', 'fullName', 'status', 'email', 'phone', 'documentNumber', 'documentType', 'lastSeen', 'userType', 'userValue'],
			}),
		[uiConfig, signOut, navigate]
	);

	const pageSizes = useRef([5, 7, 10, 25, 50, 100]);
	const [updatedPageSizes, setUpdatedPageSizes] = useState(pageSizes.current);

	useEffect(() => {
		const defaultRowsPerPage = uiConfig.dataGrid.defaultRowsPerPage;
		if (!pageSizes.current.includes(defaultRowsPerPage)) {
			const newPageSizes = [...pageSizes.current, defaultRowsPerPage].sort((a, b) => a - b);
			pageSizes.current = newPageSizes;
			setUpdatedPageSizes(newPageSizes);
		}
	}, [uiConfig]);

	const onExporting = (e: DataGridTypes.ExportingEvent) => {
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Main sheet');

		exportDataGrid({
			component: e.component,
			worksheet,
			autoFilterEnabled: true,
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
			});
		});
	};
	const onToolbarPreparing = (e: ToolbarPreparingEvent) => {
		if (e.toolbarOptions.items) {
			e.toolbarOptions.items.unshift(
				{
					location: 'before',
					widget: 'dxTextBox',
					options: {
						mode: 'search',
						placeholder: 'Buscar',
						onEnterKey: (args: { component: { option: (key: string) => string } }) => {
							e.component.searchByText(args.component.option('value'));
						},
						elementAttr: {
							class: 'finnekta-textbox-filter',
						},
					},
				},
				{
					location: 'before',
					widget: 'dxButton',
					options: {
						icon: 'filter',
						text: filterVisible ? 'Ocultar filtros' : 'Mostrar filtros',
						onClick: () => {
							setFilterVisible(!filterVisible);
						},
						elementAttr: {
							class: 'finnkekta-button-filter',
						},
					},
				}
			);
		}
	};

	const renderActionCell = (e: DataGridTypes.CellPreparedEvent) => (
		<ActionCellRenderer data={e.data} userPermissions={userPermissions} showConfirmation={showConfirmation} />
	);

	return (
		<div>
			<DataGrid
				ref={dataGridRef}
				dataSource={usersDataSource}
				showBorders={false}
				focusedRowEnabled={false}
				columnAutoWidth={true}
				columnHidingEnabled={true}
				rowAlternationEnabled={true}
				hoverStateEnabled={true}
				allowColumnReordering={true}
				allowColumnResizing={true}
				width="100%"
				className="dx-datagrid-filter-top"
				onExporting={onExporting}
				onToolbarPreparing={onToolbarPreparing}
				keyExpr={'userId'}
				columnResizingMode="widget"
				columnFixing={{ enabled: true }}
			>
				<Scrolling mode="standard" />
				<GroupPanel visible={false} />
				<FilterRow visible={filterVisible} />
				<FilterPanel visible={false} />
				<HeaderFilter visible={false} />
				<ColumnFixing enabled={false} />
				<ColumnChooser enabled={false} />
				<Grouping autoExpandAll={true} />
				<RemoteOperations filtering={true} paging={true} sorting={true} summary={false} grouping={false} groupPaging={false} />
				<Column dataField={'userId'} width={90} hidingPriority={1} caption={'Id'} visible={false} />
				<Column hidingPriority={3} dataField={'userValue'} caption={'Usuario'} />
				<Column
					hidingPriority={3}
					dataField={'documentType'}
					caption={'Tipo de documento'}
					lookup={{
						dataSource: documentTypeLookup,
						valueExpr: 'value',
						displayExpr: 'text',
					}}
				/>
				<Column hidingPriority={3} dataField={'documentNumber'} caption={'N° Documento'} />
				<Column hidingPriority={6} dataField={'fullName'} caption={'Nombre Completo'} />
				<Column
					hidingPriority={5}
					dataField={'status'}
					caption={'Estado'}
					lookup={{
						dataSource: statusLookup,
						valueExpr: 'value',
						displayExpr: 'text',
					}}
				/>
				<Column hidingPriority={2} dataField={'email'} caption={'Email'} />
				<Column hidingPriority={3} dataField={'lastSeen'} caption={'Última conexión'} dataType="datetime" format="dd/MM/yyyy HH:mm:ss" />
				<Column hidingPriority={3} dataField={'userType'} caption={'Tipo de Usuario'} visible={false} />
				<Column dataField="actions" caption="Acciones" cellRender={renderActionCell} allowFiltering={false} fixed={true} fixedPosition='right' width={164} />
				<Pager allowedPageSizes={updatedPageSizes} showPageSizeSelector={true} showNavigationButtons={true} showInfo={true} displayMode="compact" />
				<Paging defaultPageSize={uiConfig.dataGrid.defaultRowsPerPage} />
				<Export enabled={false} />
			</DataGrid>
			<Popup
				visible={confirmationVisible}
				onHiding={() => setConfirmationVisible(false)}
				dragEnabled={false}
				hideOnOutsideClick={true}
				showCloseButton={false}
				showTitle={false}
				container=".dx-viewport"
				width={500}
				height={155}
			>
				<div className="popup-box-item">
					<span className="finnetka-card-text-header">{`Este proceso va a ${statusText} a este usuario.`}</span>
					<div className="custom-action-row ">
						<Button text="CANCELAR" onClick={() => setConfirmationVisible(false)} className="finnekta-button-secondary-action" />
						<Button
							text="CONFIRMAR"
							onClick={() => {
								if (confirmationAction) {
									confirmationAction();
								}
								if (dataGridRef.current) {
									dataGridRef.current.instance.refresh(); // Refresca la grilla
								}
								setConfirmationVisible(false);
							}}
							className="finnekta-button-primary-action"
						/>
					</div>
				</div>
			</Popup>
		</div>
	);
};
