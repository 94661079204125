import React from 'react';
import { Button } from 'devextreme-react';
import { useNavigate } from 'react-router-dom';
import { UserPermissions } from '../../types/userResponse';
import { ApiKeysDataSource } from '../../types/apiKeysResponse';

interface ActionCellProps {
	data: ApiKeysDataSource;
	userPermissions: UserPermissions;
}

const ActionCell: React.FC<ActionCellProps> = ({ data, userPermissions }) => {
	const navigate = useNavigate();
	const renderPermissionButton = () => {
		return (
			<Button
			icon='mdi mdi-account-check-outline'
				stylingMode={'outlined'}
				onClick={() =>
					navigate('/apikeys/permissions', {
						state: { apiKeyData: data },
					})
				}
				className="finnekta-button-action"
				disabled={!userPermissions.canEnableApiKey}
			/>
		);
	};

	const renderEditButton = () => {
		return (
			<Button
			icon='mdi mdi-pencil-outline'
				stylingMode={'outlined'}
				onClick={() => navigate('/apikeys/edit', { state: { apiKeyData: data } })}
				className="finnekta-button-action"
				disabled={!userPermissions.canEditApiKey}
			/>
		);
	};

	return (
		<div>
			{renderPermissionButton()}
			{renderEditButton()}
		</div>
	);
};

export default ActionCell;
