import axios from 'axios';
import { ApiKeysPayload } from '../types/apiKeysResponse';
import { getRealmHeaders, getUserHeaders, getEnvironmentsHeaders } from './config';
import { UpdateApiKeyPermissionsRequest } from '../types';

export async function createApiKey(payload: ApiKeysPayload) {
	try {
		const response = await axios.post('/apiKeys', payload, {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
				...getEnvironmentsHeaders(),
			},
		});
		return {
			success: true,
			messagge: 'El Api Key fue creado con éxito',
			response: response.data,
		};
	} catch (error) {
		let errorMessage = 'El Api Key no pudo ser creado porque pasaron cosas.';
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}

export async function editApiKey(payload: ApiKeysPayload, id: string) {
	try {
		const response = await axios.put(`/apiKeys/${id}`, payload, {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
				...getEnvironmentsHeaders(),
			},
		});
		return {
			success: true,
			message: 'El Api Key fue editado con éxito',
			response: response.data,
		};
	} catch (error) {
		let errorMessage = 'El Api Key no pudo ser editado porque pasaron cosas.';
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}

export async function updatePermissions(payload: UpdateApiKeyPermissionsRequest, id: string) {
	try {
		const response = await axios.patch(`/apiKeys/permissions/${id}`, payload, {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
				...getEnvironmentsHeaders(),
			},
		});
		return {
			success: true,
			message: 'Los permisos fueron actualizados con éxito',
			response: response.data,
		};
	} catch (error) {
		let errorMessage = 'Los permisos no pudieron ser actualizados porque pasaron cosas.';
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}
