import { Button, TextBox } from 'devextreme-react';
import React, { useContext, useEffect, useState } from 'react';
import './apiKeys.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { editApiKey } from '../../api/apiKeys';
import { defaultShowTimeSToast } from '../../config/globalVariables';
import { getInitialApiKeysFormData, handleInputChange, initialApiKeysErrors, validate } from './apiKeysFormUtils';
import { ApiKeysPayload } from '../../types/apiKeysResponse';
import { showNotification } from '../../utils/showNotification';
import { UiConfigContext } from '../../contexts/uiConfig';
import { Spinner } from '../spinner/spinner';

export default function NewApiKeyForm() {
	const location = useLocation();
	const navigate = useNavigate();
	const { apiKeyData } = location.state;
	const [submitted, setSubmitted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formData, setFormData] = useState(getInitialApiKeysFormData(apiKeyData));
	const [errors, setErrors] = useState(initialApiKeysErrors);
	const { uiConfig } = useContext(UiConfigContext);

	const onSubmit = async () => {
		setSubmitted(true);
		const validationErrors = validate(formData);
		setErrors(validationErrors);
		if (Object.keys(validationErrors).length === 0) {
			setIsSubmitting(true);
			const payload: ApiKeysPayload = {
				name: formData.name,
			};
			const result = await editApiKey(payload, apiKeyData.id);
			if (result.success) {
				showNotification({ message: result.message, type: 'success', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
					navigate('/apikeys');
			} else {
				showNotification({ message: result.message, type: 'error', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
				setIsSubmitting(false);
			}
		}
	};

	useEffect(() => {
		if (submitted) {
			const newErrors = validate(formData);
			setErrors(newErrors);
		}
	}, [submitted, formData]);

	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb'}>
				<Link to={'/apikeys'}>API Keys</Link>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">Editar</span>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">{apiKeyData.name}</span>
			</h2>
			<div className={'content-block'}>
				<div className={'dx-card responsive-paddings animate__animated animate__fadeIn'}>
					<div className="finnetka-card-text-header">Configuración básica</div>
					<form onSubmit={onSubmit}>
						<div className="custom-container-form">
							<TextBox
								value={formData.name}
								onValueChanged={(e) => handleInputChange(setFormData, setErrors)('name', e.value)}
								label="Nombre"
								labelMode="floating"
								isValid={!errors.name}
								validationError={{ message: errors.name }}
							/>
							<TextBox
								value={formData.description}
								onValueChanged={(e) => handleInputChange(setFormData, setErrors)('description', e.value)}
								showClearButton={true}
								label="Descripción"
								labelMode="floating"
							/>
						</div>
						<div className="button-container">
							<Button text="SIGUIENTE" className="finnekta-button-primary-action" onClick={onSubmit} disabled={isSubmitting} />
						</div>
					</form>
				</div>
			</div>
			{isSubmitting && <Spinner />}
		</React.Fragment>
	);
}
