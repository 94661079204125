import { useContext, useState, useRef, useEffect } from 'react';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import DataGrid, {
	Column,
	Grouping,
	GroupPanel,
	Pager,
	Paging,
	FilterRow,
	FilterPanel,
	HeaderFilter,
	Export,
	Scrolling,
	RemoteOperations,
	ColumnFixing,
	ColumnChooser,
	DataGridTypes,
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { UiConfigContext } from '../../contexts/uiConfig';
import { statusLookupRoles } from '../../api/status';

import { ToolbarPreparingEvent } from 'devextreme/ui/data_grid';
import { rolesDataSource } from '../users/userFormCommons';

export const DataGridRoles = () => {

	const { uiConfig } = useContext(UiConfigContext);
	const [filterVisible, setFilterVisible] = useState(uiConfig.dataGrid.defaultFilterVisibility);
	const onToolbarPreparing = (e: ToolbarPreparingEvent) => {
		if (e.toolbarOptions.items) {
			e.toolbarOptions.items.unshift(
				{
					location: 'before',
					widget: 'dxTextBox',
					options: {
						mode: 'search',
						placeholder: 'Buscar',
						onEnterKey: (args: { component: { option: (key: string) => string } }) => {
							e.component.searchByText(args.component.option('value'));
						},
						elementAttr: {
							class: 'finnekta-textbox-filter',
						},
					},
				},
				{
					location: 'before',
					widget: 'dxButton',
					options: {
						icon: 'filter',

						text: filterVisible ? 'Ocultar filtros' : 'Mostrar filtros',
						onClick: () => {
							setFilterVisible(!filterVisible);
						},
						elementAttr: {
							class: 'finnkekta-button-filter',
						},
					},
				}
			);
		}
	};

	const pageSizes = useRef([5, 7, 10, 25, 50, 100]);
	const [updatedPageSizes, setUpdatedPageSizes] = useState(pageSizes.current);

	useEffect(() => {
		const defaultRowsPerPage = uiConfig.dataGrid.defaultRowsPerPage;
		if (!pageSizes.current.includes(defaultRowsPerPage)) {
			const newPageSizes = [...pageSizes.current, defaultRowsPerPage].sort((a, b) => a - b);
			pageSizes.current = newPageSizes;
			setUpdatedPageSizes(newPageSizes);
		}
	}, [uiConfig]);

	const onExporting = (e: DataGridTypes.ExportingEvent) => {
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Main sheet');

		exportDataGrid({
			component: e.component,
			worksheet,
			autoFilterEnabled: true,
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
			});
		});
	};
	return (
		<DataGrid
			dataSource={rolesDataSource}
			showBorders={false}
			focusedRowEnabled={false}
			columnAutoWidth={true}
			columnHidingEnabled={true}
			rowAlternationEnabled={true}
			hoverStateEnabled={true}
			allowColumnReordering={true}
			allowColumnResizing={true}
			width="100%"
			className="dx-datagrid-filter-top"
			onExporting={onExporting}
			onToolbarPreparing={onToolbarPreparing}
			columnResizingMode="widget"
		>
			<Scrolling mode="standard" />
			<GroupPanel visible={false} />
			<FilterRow visible={filterVisible} />
			<FilterPanel visible={false} />
			<HeaderFilter visible={false} />
			<ColumnFixing enabled={false} />
			<ColumnChooser enabled={false} />
			<Grouping autoExpandAll={true} />
			<RemoteOperations filtering={true} paging={true} sorting={true} summary={false} grouping={false} groupPaging={false} />
			<Column dataField={'id'} caption={'Id'} visible={false} />
			<Column dataField={'name'} caption={'Roles'} />
			<Column dataField={'description'} caption={'Descripción'} />
			<Column
				dataField={'status'}
				caption={'Estado'}
				lookup={{
					dataSource: statusLookupRoles,
					valueExpr: 'value',
					displayExpr: 'text',
				}}
			/>
			<Pager allowedPageSizes={updatedPageSizes} showPageSizeSelector={true} showNavigationButtons={true} showInfo={true} displayMode="compact" />
			<Paging defaultPageSize={uiConfig.dataGrid.defaultRowsPerPage} />
			<Export enabled={false} />
		</DataGrid>
	);
};
