import React, { useContext, useState } from 'react';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import { NumberBox } from 'devextreme-react/number-box';
import { useEnvironment } from '../../contexts/environment';
import { ButtonGroup } from 'devextreme-react/button-group';
import { LabelMode } from 'devextreme/common';
import { UiConfigContext, UiOptions } from '../../contexts/uiConfig';
import { Theme, ThemeContext } from '../../contexts/theme';
import { useEnvironmentMenu } from '../../contexts/environmentMenu';

import './EnvironmentMenu.scss';

export default function EnvironmentMenu() {

	const { environments, currentEnvironment, setCurrentEnvironment } = useEnvironment();
	const { uiConfig, setUiConfig } = useContext(UiConfigContext);
	const { setTheme } = useContext(ThemeContext);
	const [selectedTheme, setSelectedTheme] = useState(['blue']);
	const { toggleMenu }  = useEnvironmentMenu();

	const changeEnvironment = (e: any) => {
		const selectedEnvironmentCode = e.value;
		const selectedEnvironment = environments.find((env) => env.code === selectedEnvironmentCode);

		if (selectedEnvironment) {
			setCurrentEnvironment(selectedEnvironment);
			window.location.href = '/home';
		}
	};

	const changeUiConfig = (newConfig: any, reload: boolean) => {
		const updatedConfig = { ...uiConfig, ...newConfig };
		localStorage.setItem('ui_config', JSON.stringify(updatedConfig));
		setUiConfig(updatedConfig);
		if (reload) window.location.reload();
	};

	const changeTheme = (theme: Theme) => {
		localStorage.setItem('theme', theme);
		setTheme(theme);
	};

	const modeButtonOptions = [
		{ id: 'ACTIVE', text: 'Activado' },
		{ id: 'INACTIVE', text: 'Desactivado' },
	];

	const themeButtonOptions = [
		{ id: 'light', text: 'Claro' },
		{ id: 'blue', text: 'Oscuro' },
	];


	return (
		<div className={'container dx-swatch-additional bg-transparent environment-menu animate__animated animate__fadeInLeft'} >
			<div className='close-menu-container'>
				<Button
					icon="mdi mdi-close" 
					className="close-menu"
					onClick={toggleMenu}
				>

				</Button>
			</div>
			<div>
				<p>
					{' '}
					<i className="mdi mdi-brightness-4"></i>TEMA
				</p>
				<ButtonGroup
					items={themeButtonOptions}
					keyExpr="id"
					stylingMode="outlined"
					selectedItemKeys={selectedTheme}
					onSelectionChanged={(e) => {
						const selectedId = e.addedItems[0];
						if (selectedId.id === 'light') {
							changeTheme('light');
							setSelectedTheme(['light']);
						} else {
							changeTheme('blue');
							setSelectedTheme(['blue']);
						}
					}}
				/>
			</div>
			<div>
				<p>
					{' '}
					<i className="mdi mdi-laptop"></i> MODO DESARROLLADOR
				</p>
				<ButtonGroup disabled={true} items={modeButtonOptions} keyExpr="id" stylingMode="outlined" selectedItemKeys={[modeButtonOptions[1].id]} />
			</div>
			<div>
				<p>
					{' '}
					<i className="mdi mdi-application-braces"></i>AMBIENTE
				</p>
				<SelectBox
					dataSource={environments}
					displayExpr="name"
					valueExpr="code"
					value={currentEnvironment.code}
					label="Selecciona un ambiente"
					labelMode={uiConfig.labelMode as LabelMode}
					onValueChanged={changeEnvironment}
					width="100%"
					height="50px"
				/>
			</div>
			<div>
				<p>
					{' '}
					<i className="mdi mdi-cogs"></i>CONFIGURACIÓN DE UI
				</p>
				<div>
					<SelectBox
						dataSource={UiOptions.stylingMode}
						valueExpr="value"
						displayExpr="text"
						label="Selecciona un estilo"
						labelMode={uiConfig.labelMode as LabelMode}
						value={uiConfig.stylingMode}
						onValueChanged={(e) => changeUiConfig({ stylingMode: e.value }, true)}
						width="100%"
						height="50px"
					/>
				</div>
				<div>
					<SelectBox
						dataSource={UiOptions.labelMode}
						valueExpr="value"
						displayExpr="text"
						label="Selecciona un modo de etiqueta"
						labelMode={uiConfig.labelMode as LabelMode}
						value={uiConfig.labelMode}
						onValueChanged={(e) => changeUiConfig({ labelMode: e.value }, false)}
						width="100%"
						height="50px"
					/>
				</div>
				<div>
					<NumberBox
						value={uiConfig.dataGrid.defaultRowsPerPage}
						min={5}
						max={20}
						showSpinButtons={true}
						label="Selecciona la cantidad de filas de las grillas"
						labelMode={uiConfig.labelMode as LabelMode}
						onValueChanged={(e) =>
							changeUiConfig(
								{
									dataGrid: {
										...uiConfig.dataGrid,
										defaultRowsPerPage: e.value,
									},
								},
								false
							)
						}
						width="100%"
						height="50px"
					/>
				</div>
				<div>
					<SelectBox
						dataSource={UiOptions.defaultFilterVisibility}
						valueExpr="value"
						displayExpr="text"
						label="Selecciona la visibilidad de filtros en las grillas"
						labelMode={uiConfig.labelMode as LabelMode}
						value={uiConfig.dataGrid.defaultFilterVisibility}
						onValueChanged={(e) =>
							changeUiConfig(
								{
									dataGrid: {
										...uiConfig.dataGrid,
										defaultFilterVisibility: e.value,
									},
								},
								false
							)
						}
						width="100%"
						height="50px"
					/>
				</div>
				<div>
					<SelectBox
						dataSource={UiOptions.notificationsPosition}
						valueExpr="value"
						displayExpr="text"
						label="Selecciona la posicion de las notificaciones"
						labelMode={uiConfig.labelMode as LabelMode}
						value={uiConfig.notificationsPosition}
						onValueChanged={(e) => changeUiConfig({ notificationsPosition: e.value }, false)}
						width="100%"
						height="50px"
					/>
				</div>
				<div>
					<SelectBox
						dataSource={UiOptions.menuStatus}
						valueExpr="value"
						displayExpr="text"
						label="Selecciona el estado del menu"
						labelMode={uiConfig.labelMode as LabelMode}
						value={uiConfig.menuStatus}
						onValueChanged={(e) => changeUiConfig({ menuStatus: e.value }, true)}
						width="100%"
						height="50px"
					/>
				</div>
			</div>
		</div>
	);
}
