import React from 'react';
import { DataGridRoles } from './dataGridRoles';
import { DataGridRolesByEnvironments } from './dataGridRolesByEnvironments';

export default function Roles() {
	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb actual'}>Roles</h2>
			<div className={'content-block'}>
				<div className={'dx-card responsive-paddings animate__animated animate__fadeIn'}>
					{' '}
					<div className="finnetka-card-text-header">Roles Globales</div>
					<DataGridRoles />
					<br />
					<br />
					<div className="finnetka-card-text-header">Roles por Ambientes</div>
					<DataGridRolesByEnvironments />
				</div>
			</div>
		</React.Fragment>
	);
}
