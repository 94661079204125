import { DataGridDetails } from './dataGridDetails';


export interface MasterDetailProps {
    data: {
      key: string;
      data: {
        operationType: string;
      };
    };
  }

export const MasterOperationsDetailsView = (props: MasterDetailProps) => {
    return <DataGridDetails {...props} />;
  };

