import './apiKeys.scss';
import TabPanel from 'devextreme-react/tab-panel';
import { Accordion, Button, CheckBox, Popup, SpeedDialAction, Tooltip } from 'devextreme-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Actions, CustomActions, PermissionsData, Products, UpdateApiKeyPermissionsRequest } from '../../types';
import { updatePermissions } from '../../api/apiKeys';
import { defaultShowTimeSToast } from '../../config/globalVariables';
import { showNotification } from '../../utils/showNotification';
import { UiConfigContext } from '../../contexts/uiConfig';
import { Spinner } from '../spinner/spinner';

export default function ApiKeysPermissions() {
	const location = useLocation();
	const { apiKeyData } = location.state;
	const [markAllStatus, setMarkAllStatus] = useState(false);
	const [selectedActions, setSelectedActions] = useState<CustomActions[]>([]);
	const [productTypeList, setProductTypeList] = useState([
		{
			title: 'Cuentas Corrientes',
			permissions: apiKeyData.additionalInfo.permissions.filter((item: PermissionsData) => item.productType.name === 'Cuentas Corrientes'),
			disabled: false,
		},
		{
			title: 'Cuentas de Ahorro',
			permissions: apiKeyData.additionalInfo.permissions.filter((item: PermissionsData) => item.productType.name === 'Cuentas de Ahorro'),
			disabled: true,
		},
		{
			title: 'Préstamos',
			permissions: apiKeyData.additionalInfo.permissions.filter((item: PermissionsData) => item.productType.name === 'Préstamos'),
			disabled: true,
		},
		{
			title: 'CDAs',
			permissions: apiKeyData.additionalInfo.permissions.filter((item: PermissionsData) => item.productType.name === 'CADs'),
			disabled: true,
		},
		{
			title: 'Tarjetas de Crédito',
			permissions: apiKeyData.additionalInfo.permissions.filter((item: PermissionsData) => item.productType.name === 'Tarjetas de Crédito'),
			disabled: true,
		},
		{
			title: 'Seguros',
			permissions: apiKeyData.additionalInfo.permissions.filter((item: PermissionsData) => item.productType.name === 'Seguros'),
			disabled: true,
		},
	]);
	const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
	const [expandedAccordionIndex, setExpandedAccordionIndex] = useState<number>(0);

	const handleTabChange = (e: { component: { option: (name: string) => number } }) => {
		const selectedIndex = e.component.option('selectedIndex');

		if (selectedIndex !== selectedTabIndex) setExpandedAccordionIndex(0);

		setSelectedTabIndex(e.component.option('selectedIndex'));
	};

	const handleAccordionChange: (index: number) => void = (index) => {
		setExpandedAccordionIndex(index);
	};

	const handleClear = useCallback(() => {
		const clearedActions = selectedActions.map((action) => ({ ...action, status: false }));
		setSelectedActions(clearedActions);
	}, [selectedActions]);

	const popupRef = useRef<Popup>(null);
	const openPopup = useCallback(() => {
		if (popupRef.current) {
			popupRef.current.instance.show();
		}
	}, []);

	const closePopup = useCallback(() => {
		if (popupRef.current) {
			popupRef.current.instance.hide();
			handleClear();
		}
	}, [handleClear]);

	const handleMarkAll = (products: Products[], markAll: boolean) => {
		const updatedProductTypeItem = { ...productTypeList[selectedTabIndex] };

		// Actualizar los permisos solo del productTypeItem seleccionado
		updatedProductTypeItem.permissions = updatedProductTypeItem.permissions.map((permission: PermissionsData) => {
			const updatedProducts = permission.products.map((permissionProduct: Products) => {
				const updatedActions = permissionProduct.actions.map((permissionProductAction: Actions) => {
					const productFound = products.find((product) => product.actions.some((action) => action.name === permissionProductAction.name));

					return {
						...permissionProductAction,
						status: productFound && permissionProductAction.enabled,
					};
				});

				return { ...permissionProduct, actions: updatedActions };
			});

			return { ...permission, products: updatedProducts };
		});

		// Crear una nueva lista de productTypeList con el productTypeItem actualizado
		const updatedProductTypes = [...productTypeList.slice(0, selectedTabIndex), updatedProductTypeItem, ...productTypeList.slice(selectedTabIndex + 1)];

		// Actualizar el estado
		setMarkAllStatus(markAll);
		setProductTypeList(updatedProductTypes);
	};

	useEffect(() => {
		const initialActions =
			apiKeyData.additionalInfo?.permissions[0]?.products[0]?.actions.map(
				({ id, code, name, description }: { id: { _value: string }; code: string; name: string; description: string }) => ({
					id: { _value: id._value },
					code,
					name,
					description,
					status: false,
				})
			) || [];
		setSelectedActions(initialActions);
	}, [apiKeyData.additionalInfo?.permissions]);

	const handleCustomizeAccess = () => {
		openPopup();
	};

	const handleActionSave = (selectedCustomActions: CustomActions[]) => {
		const newActions = [...selectedCustomActions];
		setSelectedActions(newActions);
		handleConfirmAccess(selectedCustomActions, apiKeyData.additionalInfo?.permissions[selectedTabIndex]?.products);
		handleClear();
	};

	const handleConfirmAccess = (selectedActions: CustomActions[], products: Products[]) => {
		const updatedProductTypes = productTypeList.map((productTypeItem) => {
			productTypeItem.permissions.forEach((productTypePermissions: PermissionsData) => {
				updateProductPermissions(productTypePermissions, selectedActions, products);
			});
			return productTypeItem;
		});
		setProductTypeList(updatedProductTypes);
		closePopup();
	};

	const updateProductPermissions = (productTypePermissions: PermissionsData, selectedActions: CustomActions[], products: Products[]) => {
		productTypePermissions.products.forEach((productTypePermissionsProduct: Products) => {
			productTypePermissionsProduct.actions.forEach((productTypePermissionsProductAction: Actions) => {
				updateActionStatus(productTypePermissionsProductAction, selectedActions, products);
			});
		});
	};

	const updateActionStatus = (productTypePermissionsProductAction: Actions, selectedActions: CustomActions[], products: Products[]) => {
		products.forEach((product: Products) => {
			product.actions.forEach((productAction: Actions) => {
				if (shouldUpdateActionStatus(productAction, productTypePermissionsProductAction, selectedActions)) {
					productAction.status = findSelectedActionStatus(productAction, selectedActions);
				}
			});
		});
	};

	const shouldUpdateActionStatus = (productAction: Actions, productTypePermissionsProductAction: Actions, selectedActions: CustomActions[]) => {
		return (
			productAction.name === productTypePermissionsProductAction.name &&
			productTypePermissionsProductAction.enabled &&
			selectedActions.some((selectedAction) => selectedAction.name === productAction.name)
		);
	};

	const findSelectedActionStatus = (productAction: Actions, selectedActions: CustomActions[]) => {
		const selectedAction = selectedActions.find((action) => action.name === productAction.name);
		return selectedAction ? selectedAction.status : productAction.status;
	};

	const handleChangePermissionsValue = useCallback((e: { element: HTMLElement; value: boolean }) => {
		const actionId = e.element.getAttribute('data-action-id');

		setProductTypeList((oldList) => {
			const newList = oldList.map((productTypeItem) => ({
				...productTypeItem,
				permissions: productTypeItem.permissions.map((permission: PermissionsData) => ({
					...permission,
					products: permission.products.map((product: Products) => ({
						...product,
						actions: product.actions.map((action: Actions) => {
							return action.id._value === actionId ? { ...action, status: e.value } : action;
						}),
					})),
				})),
			}));
			return newList;
		});
	}, []);

	const { uiConfig } = useContext(UiConfigContext);
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = async () => {
		setIsSubmitting(true);
		let payload: UpdateApiKeyPermissionsRequest = { permissions: [] };

		productTypeList.forEach((productTypeItem) => {
			productTypeItem.permissions.forEach((permission: PermissionsData) => {
				payload.permissions.push(permission);
			});
		});

		const result = await updatePermissions(payload, apiKeyData.id);

		if (result.success) {
			showNotification({ message: result.message, type: 'success', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			navigate('/apikeys');
		} else {
			showNotification({ message: result.message, type: 'error', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			setIsSubmitting(false);
		}
	};

	const customTabPanelItem = (props: any) => {
		return (
			<TabPanelItem
				{...props}
				handleMarkAll={handleMarkAll}
				handleCustomizeAccess={handleCustomizeAccess}
				markAllStatus={markAllStatus}
				handleChangePermissionsValue={handleChangePermissionsValue}
				expandedAccordionIndex={expandedAccordionIndex}
				handleAccordionChange={handleAccordionChange}
			/>
		);
	};

	return (
		<>
			<h2 className="content-block breadcrumb">
				<Link to="/apikeys">API Keys</Link>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">Permisos</span>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">{apiKeyData.name}</span>
			</h2>
			<div className="content-block">
				<div className="dx-card responsive-paddings animate__animated animate__fadeIn">
					<div className="tabpanel-demo">
						<div className="widget-container">
							<div className="tab-text-header">PRODUCTOS DISPONIBLES</div>
							<TabPanel
								animationEnabled={true}
								swipeEnabled={true}
								dataSource={productTypeList}
								tabsPosition={'left'}
								className="custom-tab-align-right"
								itemComponent={customTabPanelItem}
								selectedIndex={selectedTabIndex}
								onSelectionChanged={handleTabChange}
							/>
							<SpeedDialAction
								icon="mdi mdi-content-save-cog-outline"
								label="GUARDAR PERMISOS"
								index={1}
								onClick={onSubmit}
								disabled={isSubmitting}
							/>
						</div>
					</div>
				</div>
				<Popup
					title="Aplicar a todas las cuentas"
					showTitle={true}
					width={585}
					height={280}
					showCloseButton={true}
					ref={popupRef}
					onHiding={() => closePopup()}
					hideOnOutsideClick={true}
				>
					<ItemCustomRender customActions={selectedActions} onValueSave={handleActionSave} />
				</Popup>
			</div>
			{isSubmitting && <Spinner />}
		</>
	);
}

function TabPanelItem({
	data,
	handleMarkAll,
	handleCustomizeAccess,
	markAllStatus,
	handleChangePermissionsValue,
	expandedAccordionIndex,
	handleAccordionChange,
}: {
	readonly data: { permissions: PermissionsData[] };
	readonly handleMarkAll: (products: Products[], markAll: boolean) => void;
	readonly handleCustomizeAccess: (permissions: Actions[]) => void;
	readonly markAllStatus: boolean;
	readonly handleChangePermissionsValue: (e: { element: HTMLElement; value: boolean }) => void;
	readonly expandedAccordionIndex: number;
	readonly handleAccordionChange: { index: number };
}) {
	const permissionItems = data.permissions.map((permission) => (
		<PermissionItem
			key={permission.id._value}
			productTypeTitle={permission.productType.name}
			products={permission.products}
			handleMarkAll={handleMarkAll}
			handleCustomizeAccess={handleCustomizeAccess}
			markAllStatus={markAllStatus}
			handleChangePermissionsValue={handleChangePermissionsValue}
			expandedAccordionIndex={expandedAccordionIndex}
			handleAccordionChange={handleAccordionChange}
		/>
	));
	return <div className="tabpanel-item">{permissionItems}</div>;
}

const ItemRender: React.FC<{
	readonly actions: readonly Actions[];
	readonly handleChangePermissionsValue: (e: { element: HTMLElement; value: boolean }) => void;
}> = ({ actions, handleChangePermissionsValue }) => (
	<div className="permission-settings">
		{actions.map((action) => (
			<CheckBox
				key={action.id._value}
				value={action.status}
				text={action.description}
				elementAttr={{ 'data-action-id': action.id._value }}
				disabled={!action.enabled}
				onValueChanged={(e) => {
					const element = e.element;
					const value = e.value as boolean;
					handleChangePermissionsValue({ element, value });
				}}
			/>
		))}
	</div>
);

const ItemCustomRender: React.FC<{
	readonly customActions: readonly CustomActions[];
	onValueSave: (localCustomActions: CustomActions[]) => void;
}> = ({ customActions, onValueSave }) => {
	const [localCustomActions, setLocalCustomActions] = useState<CustomActions[]>([]);

	useEffect(() => {
		const newActions = [...customActions];
		setLocalCustomActions(newActions);
	}, [customActions]);

	const handleActionChange = (id: string, newValue: boolean) => {
		const updatedActions = localCustomActions.map((action) => (action.id._value === id ? { ...action, status: newValue } : action));
		setLocalCustomActions(updatedActions);
	};

	const handleActionSave = () => {
		onValueSave(localCustomActions);
	};

	return (
		<div>
			<div className="permission-settings">
				{localCustomActions.map((customActions) => (
					<CheckBox
						key={customActions.id._value}
						value={customActions.status}
						text={customActions.description}
						onOptionChanged={(e) => {
							if (e.name === 'value') {
								handleActionChange(customActions.id._value, e.value);
							}
						}}
					/>
				))}
			</div>
			<div className="popup-box">
				<span className="popup-text-message">Tenga en cuenta que ciertas acciones pueden no estar habilitadas para todas las cuentas.</span>
				<Button className="finnekta-button-primary-action" text="CONFIRMAR" onClick={() => handleActionSave()} />
			</div>
		</div>
	);
};

const renderItemTitle = (data: Products) => `Cuenta Nro. ${data.id}`;
const renderItemContent = ({
	actions,
	handleChangePermissionsValue,
}: {
	actions: Products['actions'];
	handleChangePermissionsValue: (e: { element: HTMLElement; value: boolean }) => void;
}) => {
	return <ItemRender actions={actions} handleChangePermissionsValue={handleChangePermissionsValue} />;
};

const PermissionItem: React.FC<{
	products: Products[];
	productTypeTitle: string;
	handleMarkAll: (products: Products[], markAll: boolean) => void;
	handleCustomizeAccess: (permissions: Actions[]) => void;
	markAllStatus: boolean;
	handleChangePermissionsValue: (e: { element: HTMLElement; value: boolean }) => void;
	expandedAccordionIndex: number;
	handleAccordionChange: any;
}> = ({
	products,
	productTypeTitle,
	handleMarkAll,
	handleCustomizeAccess,
	markAllStatus,
	handleChangePermissionsValue,
	expandedAccordionIndex,
	handleAccordionChange,
}) => (
	<div className="permission-accordion-content">
		<div className="header-box">
			<span className="accordion-title">{productTypeTitle}</span>
			<div className="header-actions-box">
				<span className="text-header-title">ACCIONES RÁPIDAS</span>
				<span
					className="link-text-header"
					onClick={() => handleMarkAll(products, !markAllStatus)}
					tabIndex={0}
					onKeyDown={(e) => {
						if (e.key === 'Enter' || e.key === ' ') {
							handleMarkAll(products, !markAllStatus);
						}
					}}
				>
					Marcar todo
				</span>
				<span
					className="link-text-header"
					onClick={() => handleCustomizeAccess(products[0]?.actions)}
					tabIndex={0}
					onKeyDown={(e) => {
						if (e.key === 'Enter' || e.key === ' ') {
							handleCustomizeAccess(products[0]?.actions);
						}
					}}
				>
					Personalizar acceso
				</span>
				<i id="info-icon" className="mdi mdi-information-slab-circle-outline" />
				<div>
					<Tooltip target="#info-icon" showEvent="mouseenter" hideEvent="mouseleave" position="left" hideOnOutsideClick={false}>
						<div className="info-box">
							<span>Este es el texto que tiene</span>
							<span>que aparecer al darle hover</span>
							<span>o click sobre el elemento </span>
							<span> que se quiere explicar.</span>
						</div>
					</Tooltip>
				</div>
			</div>
		</div>
		<Accordion
			dataSource={products}
			deferRendering={true}
			animationDuration={500}
			itemTitleRender={renderItemTitle}
			itemRender={(item) => renderItemContent({ actions: item.actions, handleChangePermissionsValue })}
			selectedIndex={expandedAccordionIndex}
			onSelectionChanged={(e) => handleAccordionChange(e.component.option('selectedIndex'))}
		/>
	</div>
);
