import { useContext, useState, useRef, useEffect } from 'react';
import 'devextreme-react/text-area';
import './users.scss';
import { Button, Switch } from 'devextreme-react';
import 'devextreme/data/odata/store';
import DataGrid, {
	Column,
	Grouping,
	GroupPanel,
	Pager,
	Paging,
	FilterRow,
	FilterPanel,
	HeaderFilter,
	Export,
	Scrolling,
	RemoteOperations,
	ColumnFixing,
	ColumnChooser,
} from 'devextreme-react/data-grid';
import { UiConfigContext } from '../../contexts/uiConfig';
import { ToolbarPreparingEvent } from 'devextreme/ui/data_grid';
import { environmentsDataSource, environmentsUserDataSource } from './userFormCommons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from '../spinner/spinner';
import { showNotification } from '../../utils/showNotification';
import { changeUserEnvironments } from '../../api/users';
import { defaultShowTimeSToast } from '../../config/globalVariables';

const UserEnvironments = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { userData } = location.state;
	const { uiConfig } = useContext(UiConfigContext);
	const [filterVisible, setFilterVisible] = useState(uiConfig.dataGrid.defaultFilterVisibility);
	const dataGridRef = useRef<DataGrid>(null);
	const pageSizes = useRef([5, 7, 10, 25, 50, 100]);
	const [updatedPageSizes, setUpdatedPageSizes] = useState(pageSizes.current);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [switchState, setSwitchState] = useState<Record<string, boolean>>({});

	useEffect(() => {
		const userDataSource = environmentsUserDataSource(userData.userValue);
		userDataSource.load().then((data) => {
			const activeEnvironments = data.map((env: { environmentCode: string }) => env.environmentCode);
			const initialSwitchState = environmentsDataSource.items().reduce((acc, item) => {
				acc[item.id] = activeEnvironments.includes(item.id);
				return acc;
			}, {} as Record<string, boolean>);
			setSwitchState(initialSwitchState);
		});

		const defaultRowsPerPage = uiConfig.dataGrid.defaultRowsPerPage;
		if (!pageSizes.current.includes(defaultRowsPerPage)) {
			const newPageSizes = [...pageSizes.current, defaultRowsPerPage].sort((a, b) => a - b);
			pageSizes.current = newPageSizes;
			setUpdatedPageSizes(newPageSizes);
		}
	}, [userData.id, uiConfig, userData.userValue]);

	const onToolbarPreparing = (e: ToolbarPreparingEvent) => {
		if (e.toolbarOptions.items) {
			e.toolbarOptions.items.unshift(
				{
					location: 'before',
					widget: 'dxTextBox',
					options: {
						mode: 'search',
						placeholder: 'Buscar',
						onEnterKey: (args: { component: { option: (key: string) => string } }) => {
							e.component.searchByText(args.component.option('value'));
						},
						elementAttr: {
							class: 'finnekta-textbox-filter',
						},
					},
				},
				{
					location: 'before',
					widget: 'dxButton',
					options: {
						icon: 'filter',
						text: filterVisible ? 'Ocultar filtros' : 'Mostrar filtros',
						onClick: () => {
							setFilterVisible(!filterVisible);
						},
						elementAttr: {
							class: 'finnkekta-button-filter',
						},
					},
				}
			);
		}
	};

	const handleSwitchChange = (id: string, value: boolean) => {
		setSwitchState((prev) => ({
			...prev,
			[id]: value,
		}));
	};

	const handleSubmit = async () => {
		setIsSubmitting(true);
		const activeEnvironmentNames: string[] = Object.keys(switchState).filter((key) => switchState[key]);
		const result = await changeUserEnvironments(userData.userValue, activeEnvironmentNames);
		if (result.success) {
			showNotification({ message: result.message, type: 'success', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			navigate('/users');
			console.log('Payload enviado:', activeEnvironmentNames);
		} else {
			showNotification({ message: result.message, type: 'error', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			setIsSubmitting(false);
		}
	};

	const renderActionCell = (e: any) => (
		<Switch value={switchState[e.data.id] ?? false} onValueChanged={(args) => handleSwitchChange(e.data.id, args.value)} />
	);

	return (
		<>
			<h2 className={'content-block breadcrumb'}>
				<Link to={'/users'}>Usuarios</Link>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">Ambientes</span>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">{userData.fullName}</span>
			</h2>
			<div className={'content-block'}>
				<div className={'dx-card responsive-paddings animate__animated animate__fadeIn'}>
					<DataGrid
						ref={dataGridRef}
						dataSource={environmentsDataSource}
						showBorders={false}
						focusedRowEnabled={false}
						columnAutoWidth={true}
						columnHidingEnabled={true}
						rowAlternationEnabled={true}
						hoverStateEnabled={true}
						allowColumnReordering={true}
						allowColumnResizing={true}
						width="100%"
						className="dx-datagrid-filter-top"
						onToolbarPreparing={onToolbarPreparing}
						keyExpr={'userId'}
						columnResizingMode="widget"
						columnFixing={{ enabled: true }}
					>
						<Scrolling mode="standard" />
						<GroupPanel visible={false} />
						<FilterRow visible={filterVisible} />
						<FilterPanel visible={false} />
						<HeaderFilter visible={false} />
						<ColumnFixing enabled={false} />
						<ColumnChooser enabled={false} />
						<Grouping autoExpandAll={true} />
						<RemoteOperations filtering={true} paging={true} sorting={true} summary={false} grouping={false} groupPaging={false} />
						<Column dataField={'id'} caption={'Id'} visible={false} />
						<Column dataField={'name'} caption={'Ambiente'} />
						<Column dataField={'description'} caption={'Descripción'} />
						<Column dataField="actions" caption="" cellRender={renderActionCell} allowFiltering={false} width={150} />
						<Pager
							allowedPageSizes={updatedPageSizes}
							showPageSizeSelector={true}
							showNavigationButtons={true}
							showInfo={true}
							displayMode="compact"
						/>
						<Paging defaultPageSize={uiConfig.dataGrid.defaultRowsPerPage} />
						<Export enabled={false} />
					</DataGrid>
					<div id='bt-environments' className="button-container">
						<Button className="finnekta-button-primary-action " text="GUARDAR CAMBIOS" onClick={handleSubmit} />
					</div>
				</div>
			</div>
			{isSubmitting && <Spinner />}
		</>
	);
};

export default UserEnvironments;
