import { Button, SelectBox, TextBox } from 'devextreme-react';
import React, { useContext, useEffect, useState } from 'react';
import './users.scss';
import { Link, useNavigate } from 'react-router-dom';
import { createUser } from '../../api/users';
import { defaultShowTimeSToast } from '../../config/globalVariables';
import { ClickEvent } from 'devextreme/ui/button';
import { documentsTypesDataSource, handleClearForm, handleInputChange, initialErrors, getInitialFormData, validate } from './userFormCommons';
import { showNotification } from '../../utils/showNotification';
import { UiConfigContext } from '../../contexts/uiConfig';
import { Spinner } from '../spinner/spinner';

export default function CreateUserForm() {
	const navigate = useNavigate();
	const [submitted, setSubmitted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formData, setFormData] = useState(getInitialFormData({}));
	const [errors, setErrors] = useState(initialErrors);
	const { uiConfig } = useContext(UiConfigContext);
	const handleSubmit = async (e: React.FormEvent) => {
		setSubmitted(true);
		const validationErrors = validate(formData);
		if (Object.keys(validationErrors).length === 0) {
			setIsSubmitting(true);
			const payload = {
				fullName: formData.fullName,
				userValue: formData.documentNumber,
				documentType: formData.documentType,
				documentNumber: formData.documentNumber,
				email: formData.email,
				phone: formData.phone,
				isActive: true,
			};
			const result = await createUser(payload);
			if (result.success) {
				showNotification({ message: result.message, type: 'success', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
					navigate('/users');
			} else {
				showNotification({ message: result.message, type: 'error', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
				setIsSubmitting(false);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	const handleButtonClick = (e: ClickEvent) => {
		e.event!.preventDefault();
		handleSubmit(e as unknown as React.FormEvent);
	};

	useEffect(() => {
		if (submitted) {
			const newErrors = validate(formData);
			setErrors(newErrors);
		}
	}, [submitted, formData]);
	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb'}>
				<Link to={'/users'}>Usuarios</Link>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">Crear usuario</span>
			</h2>
			<div className={'content-block'}>
				<div className={'dx-card responsive-paddings animate__animated animate__fadeIn'}>
					<div className="finnetka-card-text-header">Configuración básica</div>
					<form onSubmit={handleSubmit}>
						<div className="custom-container-form">
							<TextBox
								value={formData.fullName}
								onValueChanged={(e) => handleInputChange(setFormData, setErrors)('fullName', e.value)}
								label="Nombre Completo"
								isValid={!errors.fullName}
								validationError={{ message: errors.fullName }}
							/>
							<SelectBox
								label="Tipo de documento"
								dataSource={documentsTypesDataSource}
								valueExpr="id"
								displayExpr="description"
								value={formData.documentType}
								onValueChanged={(e) => handleInputChange(setFormData, setErrors)('documentType', e.value)}
								isValid={!errors.documentType}
								validationError={{ message: errors.documentType }}
							/>
							<TextBox
								value={formData.documentNumber}
								onValueChanged={(e) => handleInputChange(setFormData, setErrors)('documentNumber', e.value)}
								label="Número de documento"
								isValid={!errors.documentNumber}
								validationError={{ message: errors.documentNumber }}
							/>
							<TextBox
								value={formData.email}
								onValueChanged={(e) => handleInputChange(setFormData, setErrors)('email', e.value)}
								label="Correo electrónico"
								mode="email"
								isValid={!errors.email}
								validationError={{ message: errors.email }}
							/>
							<TextBox
								value={formData.phone}
								onValueChanged={(e) => handleInputChange(setFormData, setErrors)('phone', e.value)}
								label="Número de celular"
								mode="tel"
								isValid={!errors.phone}
								validationError={{ message: errors.phone }}
							/>
						</div>
						<br />
						<br />
						<div className="button-container">
							<Button text="LIMPIAR FORMULARIO" className="finnekta-button-secondary-action" onClick={handleClearForm(setFormData)} />
							<Button text="CREAR USUARIO" className="finnekta-button-primary-action" onClick={handleButtonClick} disabled={isSubmitting} />
						</div>
					</form>
				</div>
			</div>
			{isSubmitting && <Spinner />}
		</React.Fragment>
	);
}
