import React, { createContext, useCallback, useContext, useState, useMemo } from 'react';

interface EnvironmentMenuContextProps {
  menuEnvStatus: number;
  toggleMenu: () => void;
}

const EnvironmentMenuContext = createContext<EnvironmentMenuContextProps | undefined>(undefined);

const MenuEnvStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
};

export const EnvironmentMenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [menuEnvStatus, setMenuEnvStatus] = useState(MenuEnvStatus.Closed);

  const toggleMenu = useCallback(() => {
    setMenuEnvStatus((prev) =>
      prev === MenuEnvStatus.Closed ? MenuEnvStatus.Opened : MenuEnvStatus.Closed
    );
  }, []);

  const contextValue = useMemo(
    () => ({ menuEnvStatus, toggleMenu }),
    [menuEnvStatus, toggleMenu]
  );



  return (
    <EnvironmentMenuContext.Provider value={contextValue}>
      {children}
    </EnvironmentMenuContext.Provider>
  );
};

export const useEnvironmentMenu = () => {
  const context = useContext(EnvironmentMenuContext);
  if (!context) {
    throw new Error('useEnvironmentMenu must be used within an EnvironmentMenuProvider');
  }
  return context;
};