import { ApiKeysData, ErrorApiKeyMessages } from "../../types/apiKeysResponse";

export const getInitialApiKeysFormData = (apiKeyData: Partial<ApiKeysData> = {}): ApiKeysData => {
    return {
        name: apiKeyData.name ?? '', 
        description: apiKeyData.description ?? '', 
        status: apiKeyData.status === 'ACTIVE' ? 'ACTIVO' : 'INACTIVO',
    };
};

export const initialApiKeysErrors: ErrorApiKeyMessages = {
    name: '', 
};

export const handleClearForm = (setFormData: React.Dispatch<React.SetStateAction<ApiKeysData>>) => () => {
    setFormData(getInitialApiKeysFormData({}));
};

export const validate = (formData: ApiKeysData): ErrorApiKeyMessages => {
    const newErrors: ErrorApiKeyMessages = {};
    if (!formData.name) newErrors.name = 'El nombre es requerido';
    return newErrors;
};
// Manejo de cambios en los campos del formulario
export const handleInputChange =
	(setFormData: React.Dispatch<React.SetStateAction<ApiKeysData>>, setErrors: React.Dispatch<React.SetStateAction<ErrorApiKeyMessages>>) =>
	(field: keyof ApiKeysData, value: string | string[]) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[field]: value,
		}));
		setErrors((prevErrors) => ({
			...prevErrors,
			[field]: '',
		}));
	};

