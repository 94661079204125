import React, { useRef } from 'react';
import { Drawer, Template, ScrollView } from 'devextreme-react';
import { useScreenSize } from '../../utils/media-query';
import { EnvironmentMenu, Footer } from '../../components';
import { useEnvironmentMenu } from '../../contexts/environmentMenu';
import './environment-drawer.scss';

export default function EnvironmentDrawer({ children }: Readonly<React.PropsWithChildren>) {
	const scrollViewRef = useRef<ScrollView>(null);
	const { isXSmall, isLarge } = useScreenSize();
	const { menuEnvStatus } = useEnvironmentMenu();

	return (
		<div className="environment-menu-drawer">
			<Drawer
				className={`environment-drawer-${menuEnvStatus === MenuEnvStatus.Opened ? 'open' : 'close'}`}
				position={'right'}
				openedStateMode={'shrink'}
				revealMode={isXSmall ? 'slide' : 'expand'}
				minSize={0}
				maxSize={250}
				shading={!isLarge}
				opened={menuEnvStatus === MenuEnvStatus.Opened}
				template={'menu'}
			>
				<div className={'container'}>
					<ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
						<div className={'content'}>
							{React.Children.map(children, (item: any) => {
								return item.type !== Footer && item;
							})}
						</div>
						<div className={'content-block'}>
							{React.Children.map(children, (item: any) => {
								return item.type === Footer && item;
							})}
						</div>
					</ScrollView>
				</div>
				<Template name={'menu'}>
					<EnvironmentMenu />
				</Template>
			</Drawer>
		</div>
	);
}

const MenuEnvStatus = {
	Closed: 1,
	Opened: 2,
	TemporaryOpened: 3,
};
