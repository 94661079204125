import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import { getRoutes } from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout, EnvironmentDrawer } from './layouts';
import { Footer } from './components';
import { useAuth } from './contexts/auth';

export default function Content() {
	const { user } = useAuth();
	const routes = getRoutes(user);
	return (
		<>
			<SideNavBarLayout title={appInfo.title}>
				<Routes>
					{routes.map(({ path, element }) => (
						<Route key={path} path={path} element={element} />
					))}
					<Route path="*" element={<Navigate to="/home" />} />
				</Routes>
				<Footer>
					<div className="footer-content">
						<div className="left-content">
							Copyright © 2024-{new Date().getFullYear()} {appInfo.title}
						</div>
						<div className="right-content">
							Desarrollado por{' '}
							<a href="https://www.roshka.com/" target="_blank" rel="noopener noreferrer author">
								Roshka S.A
							</a>{' '}
						</div>
					</div>
				</Footer>
			</SideNavBarLayout>
			<EnvironmentDrawer />
		</>
	);
}
