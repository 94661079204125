import './spinner.scss';

export const Spinner = () => {
	return (
		<div className="spinner-container">
			<div className='box'>
				
				<div className="semipolar-spinner">
					<div className="ring"></div>
					<div className="ring"></div>
					<div className="ring"></div>
					<div className="ring"></div>
					<div className="ring"></div>
				</div>
                <span className="spinner-text">Cargando... esto solo tomará un momento.</span>
			</div>
		</div>
	);
};
