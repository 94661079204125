import DataSource from 'devextreme/data/data_source';
import { getRealmHeaders, getUserHeaders } from '../../api/config';
import { apiBaseUrl } from '../../config/globalVariables';
import { showNotification } from '../../utils/showNotification';
import { ErrorUserMessages, FormDataUsers } from '../../types/userResponse';
import { Request, Error, DocumentType } from '../../types';

const uiConfigString = localStorage.getItem('ui_config');
const uiConfig = uiConfigString ? JSON.parse(uiConfigString) : null;

const notificationsPosition = uiConfig ? uiConfig?.notificationsPosition : uiConfig?.DefaultUiConfig?.notificationsPosition;

// Configuración de la fuente de datos para tipos de documentos
export const documentsTypesDataSource = new DataSource<DocumentType>({
	store: {
		version: 4,
		type: 'odata',
		key: 'id',
		url: `${apiBaseUrl}documenttypes`,
		errorHandler: (error: Error) => {
			console.error(error.httpStatus);
			if (error.httpStatus === 401) {
				showNotification({
					message: 'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
					type: 'error',
					displayTime: 12000,
					position: notificationsPosition,
				});
			}
		},
	},
	select: ['id', 'description'],
});

// Configuración de la fuente de datos para roles
export const rolesDataSource = new DataSource({
	store: {
		version: 4,
		type: 'odata',
		key: 'id',
		url: `${apiBaseUrl}getGlobalRoles`,
		beforeSend: (request: Request) => {
			request.headers = {
				...getRealmHeaders(),
				...getUserHeaders(),
			};
		},
		errorHandler: (error: Error) => {
			if (error.httpStatus === 401) {
				showNotification({
					message: 'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
					type: 'error',
					displayTime: 12000,
					position: notificationsPosition,
				});
			}
		},
	},
	select: ['id', 'name', 'description', 'type', 'status'],
});

export const rolesEnvironmentsDataSource = new DataSource({
	store: {
		version: 4,
		type: 'odata',
		key: 'id',
		url: `${apiBaseUrl}getRolesByEnvironments`,
		beforeSend: (request: Request) => {
			request.headers = {
				...getRealmHeaders(),
				...getUserHeaders(),
			};
		},
		errorHandler: (error: Error) => {
			if (error.httpStatus === 401) {
				showNotification({
					message: 'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
					type: 'error',
					displayTime: 12000,
					position: notificationsPosition,
				});
			}
		},
	},
	select: ['id', 'name', 'description', 'type', 'status'],
});
//Función para obtener los roles activos del usuario
export const rolesUserDataSource = (userValue: string) =>
	new DataSource({
		store: {
			version: 4,
			type: 'odata',
			key: 'id',
			url: `${apiBaseUrl}users/${userValue}/roles`,
			beforeSend: (request: Request) => {
				request.headers = {
					...getRealmHeaders(),
					...getUserHeaders(),
				};
			},
			errorHandler: (error: Error) => {
				if (error.httpStatus === 401) {
					showNotification({
						message: 'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
						type: 'error',
						displayTime: 12000,
						position: notificationsPosition,
					});
				}
			},
		},
		select: [''],
	});

//Función para obtener los ambientes disponibles del usuario
export const environmentsDataSource = new DataSource({
	store: {
		version: 4,
		type: 'odata',
		key: 'id',
		url: `${apiBaseUrl}environments`,
		beforeSend: (request: Request) => {
			request.headers = {
				...getRealmHeaders(),
				...getUserHeaders(),
			};
		},
		errorHandler: (error: Error) => {
			if (error.httpStatus === 401) {
				showNotification({
					message: 'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
					type: 'error',
					displayTime: 12000,
					position: notificationsPosition,
				});
			}
		},
	},
	select: ['id', 'name', 'description', 'coreBaseUrl', 'bolemBankBaseUrl', 'cssClass'],
});

//Función para obtener los ambientes activos del usuario
export const environmentsUserDataSource = (userValue: string) =>
	new DataSource({
		store: {
			version: 4,
			type: 'odata',
			key: 'id',
			url: `${apiBaseUrl}users/${userValue}/environments`,
			beforeSend: (request: Request) => {
				request.headers = {
					...getRealmHeaders(),
					...getUserHeaders(),
				};
			},
			errorHandler: (error: Error) => {
				if (error.httpStatus === 401) {
					showNotification({
						message: 'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
						type: 'error',
						displayTime: 12000,
						position: notificationsPosition,
					});
				}
			},
		},
		select: ['environmentCode'],
	});

// Función para obtener los datos iniciales del formulario
export const getInitialFormData = (userData: Partial<FormDataUsers> = {}): FormDataUsers => ({
	fullName: userData.fullName ?? '',
	email: userData.email ?? '',
	phone: userData.phone ?? '',
	documentType: userData.documentType ?? '',
	documentNumber: userData.documentNumber ?? '',
	userValue: userData.userValue ?? '',
});

// Errores iniciales del formulario
export const initialErrors: ErrorUserMessages = {
	fullName: '',
	email: '',
	documentType: '',
	documentNumber: '',
	userValue: '',
	phone: '',
};

// Manejo de cambios en los campos del formulario
export const handleInputChange =
	(setFormData: React.Dispatch<React.SetStateAction<FormDataUsers>>, setErrors: React.Dispatch<React.SetStateAction<ErrorUserMessages>>) =>
	(field: keyof FormDataUsers, value: string | string[]) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[field]: value,
		}));
		setErrors((prevErrors) => ({
			...prevErrors,
			[field]: '',
		}));
	};

// Función para limpiar el formulario
export const handleClearForm = (setFormData: React.Dispatch<React.SetStateAction<FormDataUsers>>) => () => {
	setFormData(getInitialFormData());
};

// Función de validación del formulario
export const validate = (formData: FormDataUsers): ErrorUserMessages => {
	const newErrors: ErrorUserMessages = {};

	if (!formData.fullName) newErrors.fullName = 'El nombre completo es requerido';

	if (!formData.email) {
		newErrors.email = 'El correo electrónico es requerido';
	} else {
		const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
		if (!emailRegex.test(formData.email)) {
			newErrors.email = 'El correo electrónico no es válido';
		}
	}

	if (!formData.phone) {
		newErrors.phone = 'El número de celular es requerido';
	} else {
		const phoneRegex = /^09[6789]\d{7}$/;
		if (!phoneRegex.test(formData.phone)) {
			newErrors.phone = 'El número de celular debe tener alguno de estos formatos 098, 097, 096, 099';
		}
	}

	if (!formData.documentType) newErrors.documentType = 'El tipo de documento es requerido';
	if (!formData.documentNumber) newErrors.documentNumber = 'El número de documento es requerido';

	return newErrors;
};
