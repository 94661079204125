import React, {
	useState,
	useEffect,
	createContext,
	useContext,
	useCallback,
	useMemo,
} from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import type { User, AuthContextType } from '../types';

type AuthProviderProps = Readonly<React.PropsWithChildren<unknown>>;
function AuthProvider(props: AuthProviderProps) {
	const [user, setUser] = useState<User>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async function () {
			const result = await getUser();
			if (result.isOk) {
				setUser(result.data);
			}

			setLoading(false);
		})();
	}, []);

	const signIn = useCallback(async (userValue: string, password: string) => {
		const result = await sendSignInRequest(userValue, password);
		if (result.isOk) {
			setUser(result.data);
		}

		return result;
	}, []);

	const signOut = useCallback(() => {
		setUser(undefined);
		sessionStorage.removeItem('user_access_token');
		sessionStorage.removeItem('realm_access_token');
		localStorage.removeItem('selectedEnvironment');
	}, []);

	const authValue = useMemo(() => ({ user, signIn, signOut, loading }), [user, signIn, signOut, loading]);

	return (
		<AuthContext.Provider
			value={authValue}
			{...props}
		/>
	);
}

const AuthContext = createContext<AuthContextType>({
	loading: false,
} as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
