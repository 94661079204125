import axios from 'axios';
import { getRealmHeaders, getUserHeaders } from './config';
import { UserNewPassword, UserPayload } from '../types/userResponse';

export async function createUser(payload: UserPayload) {
	try {
		const response = await axios.post('/users', payload, {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
			},
		});
		return {
			success: true,
			message: 'El Usuario fue creado con éxito',
			response: response.data,
		};
	} catch (error) {
		let errorMessage = 'El Usuario no pudo ser creado porque pasaron cosas.';
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}

export async function editUser(payload: UserPayload) {
	try {
		const response = await axios.put(`/users`, payload, {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
			},
		});
		return {
			success: true,
			message: 'El Usuario fue editado con éxito',
			response: response.data,
		};
	} catch (error) {
		let errorMessage = 'El Usuario no pudo ser editado porque pasaron cosas.';
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}

export async function changeStatusUser(userValue: string, statusText: string) {
	const action = statusText === 'ACTIVE' ? 'activado' : 'inactivado';

	try {
		const response = await axios.patch(
			`/users/${userValue}/status`,
			{ status: statusText },
			{
				headers: {
					...getRealmHeaders(),
					...getUserHeaders(),
				},
			}
		);
		return {
			success: true,
			message: `El usuario fue ${action} correctamente.`,
			response: response.data,
		};
	} catch (error) {
		let errorMessage = `El Usuario no pudo ser ${action} porque pasaron cosas.`;
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}

export async function validateNewPassword(payload: UserNewPassword) {
	try {
		const response = await axios.patch('/users/password', payload, {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
			},
		});
		return {
			success: true,
			message: 'La contraseña fue cambiada con éxito',
			response: response.data,
		};
	} catch (error) {
		let errorMessage = 'No se pudo cambiar la contraseña';
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}

export async function changeUserEnvironments(userValue: string, environments: string[]) {
	try {
		const response = await axios.patch(
			`/users/${userValue}/environments`,
			{ userEnvironments: environments },
			{
				headers: {
					...getRealmHeaders(),
					...getUserHeaders(),
				},
			}
		);
		return {
			success: true,
			message: 'Los ambientes del usuario fueron actualizados con éxito',
			response: response.data,
		};
	} catch (error) {
		let errorMessage = 'Los ambientes del usuario no pudieron ser actualizados porque pasaron cosas.';

		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}

export async function changeUseRolesByEnvironments(
	userValue: string,
	userRoles: { global: { roles: string[] }; environments: { code: any; name: any; roles: string[] }[] }
) {
	try {
		const response = await axios.patch(
			`/users/${userValue}/roles`,
			{ userRoles },
			{
				headers: {
					...getRealmHeaders(),
					...getUserHeaders(),
				},
			}
		);
		return {
			success: true,
			message: 'Los roles del ambiente del usuario fueron actualizados con éxito',
			response: response.data,
		};
	} catch (error) {
		let errorMessage = 'Los roles del ambiente del usuario no pudieron ser actualizados porque pasaron cosas.';
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}
