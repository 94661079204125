import axios from 'axios';
import { getRealmHeaders, getUserHeaders } from './config';

export async function getRolesEnvironments() {
	try {
		const response = await axios.get('getrolesbyenvironments', {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
			},
		});
		return response.data.value;
	} catch(error) {
		let errorMessage = 'No se pudieron recuperar los roles por ambientes.';
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}

export async function getRolesByUser(userValue: string) {
	try {
		const response = await axios.get(`users/${userValue}/roles`, {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
			},
		});
		return response.data;
	} catch(error) {
		let errorMessage = 'No se pudieron recuperar los roles globales.';
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}
