

// Acciones del Usuario
const Actions = {
    FINNEKTA_WEB_ADD_USERS: "finnekta.web.add.users",
    FINNEKTA_WEB_LIST_USERS: "finnekta.web.list.users",
    FINNEKTA_WEB_EDIT_USERS: "finnekta.web.edit.users",
    FINNEKTA_WEB_ENABLE_USERS: "finnekta.web.enable.users",
    FINNEKTA_APIKEY_ADD: "finnekta.apikey.add",
    FINNEKTA_APIKEY_EDIT: "finnekta.apikey.edit",
    FINNEKTA_APIKEY_LIST: "finnekta.apikey.list",
    FINNETKA_APIKEY_ENABLE: "finnekta.web.enable.apikey",
    FINNEKTA_OPERATIONS_LIST: "finnekta.operations.list",
};

// Función para mapear y verificar acciones del usuario
export function mapUserActions(actions: string[]) {
    return {
        canViewUserList: actions.includes(Actions.FINNEKTA_WEB_LIST_USERS),
        canCreateUser: actions.includes(Actions.FINNEKTA_WEB_ADD_USERS),
        canViewApiKeyList: actions.includes(Actions.FINNEKTA_APIKEY_LIST),
        canCreateApiKey: actions.includes(Actions.FINNEKTA_APIKEY_ADD),
        canEditApiKey: actions.includes(Actions.FINNEKTA_APIKEY_EDIT),
        canEditUser: actions.includes(Actions.FINNEKTA_WEB_EDIT_USERS),
        canEnableUser: actions.includes(Actions.FINNEKTA_WEB_ENABLE_USERS),
        canEnableApiKey: actions.includes(Actions.FINNETKA_APIKEY_ENABLE),
        canViewOperationsList: actions.includes(Actions.FINNEKTA_OPERATIONS_LIST),
    };
}

//Funciones disponibles del usuario
export function getUserPermissions(user: any) {

    if (!user) {
        return {
            canViewUserList: false,
            canCreateUser: false,
            canViewApiKeyList: false,
            canCreateApiKey: false,
            canEditApiKey: false,
            canEditUser: false,
            canEnableUser: false,
            canEnableApiKey: false,
            canViewOperationsList: false,
        };
    }

    const storedEnvironment = localStorage.getItem('selectedEnvironment');
    let currentEnvironmentActions: string[] = [];
    if (storedEnvironment) {
        const environment = JSON.parse(storedEnvironment);
        currentEnvironmentActions = environment.actions || [];
    }

    const combinedActions = [...(user.actions || []), ...currentEnvironmentActions];

    return mapUserActions(combinedActions);
}
