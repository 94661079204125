import React, { useCallback, useContext, useMemo } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import './Header.scss';
import type { HeaderProps as OriginalHeaderProps } from '../../types';
import logoAppLight from '../../assets/img/logo-only-light.svg';
import logoAppDark from '../../assets/img/logo-light.svg';
import logoBankLight from '../../assets/img/logo-bank-light.svg';
import logoBankDark from '../../assets/img/logo-bank-dark.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ThemeContext } from '../../contexts/theme';
import { useAuth } from '../../contexts/auth';
import { useScreenSize } from '../../utils/media-query';
import { useEnvironment } from '../../contexts/environment';
import { useEnvironmentMenu } from '../../contexts/environmentMenu';

type ReadonlyHeaderProps = Readonly<OriginalHeaderProps>;

export default function Header({ menuToggleEnabled, toggleMenu }: ReadonlyHeaderProps) {
	const { theme } = useContext(ThemeContext);
	const { user, signOut } = useAuth();
	const navigate = useNavigate();
	const { currentEnvironment } = useEnvironment();
	const location = useLocation();
	const isUserScreen = location.pathname.includes('/users');
	const isRolesScreen = location.pathname === '/roles';
	const { toggleMenu: toggleEnvironmentMenu } = useEnvironmentMenu();

	const navigateToChangePassword = useCallback(() => {
		navigate('/new-password');
	}, [navigate]);
	const { isXSmall, isSmall } = useScreenSize();

	const menuItems = useMemo(
		() => [
			{
				text: 'Cambiar Contraseña',
				icon: 'mdi mdi-repeat-variant',
				onClick: navigateToChangePassword,
			},
			{
				text: 'Cerrar sesión',
				icon: 'mdi mdi-logout',
				onClick: signOut,
			},
		],
		[navigateToChangePassword, signOut]
	);

	return (
		<header className={'header-component'}>
			<Toolbar className={'header-toolbar animate__animated animate__slideInDown'}>
				<Item visible={menuToggleEnabled} location={'before'} widget={'dxButton'} cssClass={'menu-button'}>
					<Button icon="menu" stylingMode="text" onClick={toggleMenu} />
				</Item>
				<Item location={'before'} cssClass={'header-logo'}>
					{
						<Link to="/" className="animate__animated animate__flipInY">
							<img src={theme === 'light' ? logoAppLight : logoAppDark} alt="System Logo" data-theme={theme} />{' '}
						</Link>
					}
				</Item>
				{!isXSmall && !isSmall && (
					<Item location={'before'} cssClass={'header-logo'} render={() => <span className="logo-text">User Assistance Hub </span>}></Item>
				)}
				{!isXSmall && (
					<Item
						location={'center'}
						cssClass={'header-logo'}
						render={() => (
							<div className="logo-bank-box">
								<img src={theme === 'light' ? logoBankDark : logoBankLight} alt="System Logo" data-theme={theme} className="logo-bank-img" />
								<span className="logo-text">AMBIENTE: {isUserScreen || isRolesScreen ? 'GLOBAL' : currentEnvironment.name.toUpperCase()}</span>
							</div>
						)}
					></Item>
				)}

				<Item
					location={'after'}
					locateInMenu="auto"
					render={() => (
						<span className="user-name">
							<i id="icon-user-name" className="mdi mdi-account-circle"></i>
							{user!.fullName}
						</span>
					)}
					cssClass="user-name"
				></Item>
				<Item location="after">
					<Button
						icon="optionsgear"
						stylingMode="text"
						onClick={toggleEnvironmentMenu}
						className="header-environment-button"
					/>
				</Item>
				{menuItems.map((item) => (
					<Item
						key={item.text}
						locateInMenu="always"
						widget="dxButton"
						options={{
							text: item.text,
							icon: item.icon,
							onClick: item.onClick,
						}}
					/>
				))}
			</Toolbar>
		</header>
	);
}
