import React, { useState, createContext, useContext, useMemo, useEffect } from 'react';
import { useAuth } from './auth';
import { EnvironmentDto } from '../types';

const DefaultEnvironment: EnvironmentDto = {
	code: '',
	name: 'Sin ambiente',
	actions: [],
};

interface EnvironmentContextType {
	environments: EnvironmentDto[];
	currentEnvironment: EnvironmentDto;
	setCurrentEnvironment: (environment: EnvironmentDto) => void;
}

const EnvironmentContext = createContext<EnvironmentContextType | undefined>(undefined);

const useEnvironment = () => {
	const context = useContext(EnvironmentContext);
	if (!context) {
		throw new Error('useEnvironment debe usarse dentro de EnvironmentProvider');
	}
	return context;
};

type EnvironmentProviderProps = Readonly<React.PropsWithChildren<{}>>;

const EnvironmentProvider: React.FC<EnvironmentProviderProps> = ({ children }) => {
	const { user } = useAuth();

	const environments = useMemo(() => {
		const envs = user?.environments.map(({ code, name, actions }) => ({ code, name, actions })) ?? [];
		return envs;
	}, [user]);

	const [currentEnvironment, setCurrentEnvironment] = useState<EnvironmentDto>(() => {
		const storedEnvironment = localStorage.getItem('selectedEnvironment');
		if (storedEnvironment) {
			return JSON.parse(storedEnvironment);
		}
		return DefaultEnvironment;
	});

	useEffect(() => {
		if (!user) {
			return;
		}
		if (environments.length > 0 && !localStorage.getItem('selectedEnvironment')) {
			setCurrentEnvironment(environments[0]);
			localStorage.setItem('selectedEnvironment', JSON.stringify(environments[0]));
		} else if (environments.length === 0) {
			setCurrentEnvironment(DefaultEnvironment);
			localStorage.setItem('selectedEnvironment', JSON.stringify(DefaultEnvironment));
		}
	}, [environments, user]);

	const updateEnvironment = (environment: EnvironmentDto) => {
		setCurrentEnvironment(environment);
		localStorage.setItem('selectedEnvironment', JSON.stringify(environment));
	};

	const environmentValue = useMemo(
		() => ({
			environments,
			currentEnvironment,
			setCurrentEnvironment: updateEnvironment,
		}),
		[environments, currentEnvironment]
	);

	return <EnvironmentContext.Provider value={environmentValue}>{children}</EnvironmentContext.Provider>;
};
export { EnvironmentProvider, useEnvironment };
