export function getRealmHeaders() {
	return {
		'Content-Type': 'application/json',
		'X-RshkMichi-Realm-Token': sessionStorage.getItem('realm_access_token')!,
	};
}

export function getUserHeaders() {
	return {
		'Content-Type': 'application/json',
		'X-RshkMichi-User-Token': sessionStorage.getItem('user_access_token')!,
	};
}

export function getEnvironmentsHeaders() {
	return {
		'Content-Type': 'application/json',
		'X-Rshk-Environment': JSON.parse(localStorage.getItem('selectedEnvironment')!).code,
	};
}
