import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, PatternRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useAuth } from '../../contexts/auth';
import { request2FaCode, validate2FaCode, newPasswordChange } from '../../api/auth';
import { RequestCode2FaInfo } from '../../types/loginResponse';
import { usePasswordEditorOptions, useConfirmPasswordEditorOptions, useAddAppLoginClass } from '../form-commons/FormCommons';
import { defaultShowTimeSToast } from '../../config/globalVariables';
import './FirstLoginForm.scss';
import { showNotification } from '../../utils/showNotification';
import { UiConfigContext } from '../../contexts/uiConfig';

export default function FirstLoginForm() {
	const { user, signOut } = useAuth();
	const formData = useRef({ code: '' });
	const formDataStep3 = useRef({ password: '', confirmPassword: '' });
	const [userInput, setUserInput] = useState('');
	const [currentStep, setCurrentStep] = useState(1);
	const [token, setToken] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const passwordEditorOptions = usePasswordEditorOptions();
	const { uiConfig } = useContext(UiConfigContext);
	const confirmPasswordEditorOptions = useConfirmPasswordEditorOptions();
	const pswPattern = '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*?&#-])[A-Za-z0-9@$!%*?&#-]{8,30}$';
	const [attemptsLeft, setAttemptsLeft] = useState<number>(3);
	const [showResendButton, setShowResendButton] = useState(false);

	const handleCodeInput = (e: any) => {
		const codeAsString = e.value?.toString() || '';
		setUserInput(codeAsString);
		formData.current = { code: codeAsString };
	};

	const createRequestPayload = (type: string, name: string, delivery: string): RequestCode2FaInfo => {
		return {
			s2faType: type,
			s2faName: name,
			s2faDelivery: delivery,
		};
	};

	const handleRequest2FaCode = useCallback(async () => {
		try {
			let requestPayload;
			if (currentStep === 1) {
				requestPayload = createRequestPayload('SOFT_TOKEN_SMS', 'SOFT_TOKEN_SMS', user!.phone);
			} else if (currentStep === 2) {
				requestPayload = createRequestPayload('SOFT_TOKEN_EMAIL', 'SOFT_TOKEN_EMAIL', user!.email);
			}
			if (requestPayload) {
				const result = await request2FaCode(requestPayload);
				if (result.isOk) {
					setToken(result.token);
				} else {
					showNotification({
						message: 'Error en la solictud del código',
						type: 'error',
						displayTime: defaultShowTimeSToast,
						position: uiConfig.notificationsPosition,
					});
				}
			}
		} catch (error) {
			console.error('Error en handleRequest:', error);
		}
	}, [currentStep, user, uiConfig.notificationsPosition]);

	const handleValidate2FaCode = async (userInput: string) => {
		if (!token) {
			showNotification({
				message: 'No hay token disponible para validar',
				type: 'error',
				displayTime: defaultShowTimeSToast,
				position: uiConfig.notificationsPosition,
			});
			return;
		}

		setLoading(true);
		const validatePayload = {
			token,
			type: currentStep === 1 ? 'SOFT_TOKEN_SMS' : 'SOFT_TOKEN_EMAIL',
			value: userInput,
		};

		try {
			const response = await validate2FaCode(validatePayload);
			if (response.success) {
				if (response.status === 'VALIDATED') {
					if (currentStep === 1) {
						setCurrentStep(2);
						setUserInput('');
						formData.current = { code: '' };
					} else if (currentStep === 2) {
						setCurrentStep(3);
					}
				} else {
					showNotification({
						message: 'Código inválido',
						type: 'error',
						displayTime: defaultShowTimeSToast,
						position: uiConfig.notificationsPosition,
					});
					setAttemptsLeft((prev: number) => {
						const newAttempts = prev - 1;

						return newAttempts;
					});
				}
			} else {
				showNotification({
					message: response.message,
					type: 'error',
					displayTime: defaultShowTimeSToast,
					position: uiConfig.notificationsPosition,
				});
				setShowResendButton(true);
			}
		} catch (error) {
			console.error('Error en la validación del código', error);
		} finally {
			setLoading(false);
		}
	};

	const handleResend2FaCode = () => {
		setAttemptsLeft(3);
		setShowResendButton(false);
		handleRequest2FaCode();
	};

	const handleNewPasswordChange = async (newPassword: string) => {
		try {
			const result = await newPasswordChange(newPassword);
			if (result.success) {
				showNotification({ message: result.message, type: 'success', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			} else {
				showNotification({ message: result.message, type: 'error', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			}
		} catch (error) {
			console.error('Error al cambiar contraseña', error);
		}
	};

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (currentStep === 1 || currentStep === 2) {
			handleValidate2FaCode(formData.current.code);
		} else {
			const password = formDataStep3.current.password;
			const confirmPassword = formDataStep3.current.confirmPassword;
			if (password === confirmPassword) {
				handleNewPasswordChange(password);
				setCurrentStep(4);
			} else {
				showNotification({
					message: 'Las contraseñas no coinciden',
					type: 'error',
					displayTime: defaultShowTimeSToast,
					position: uiConfig.notificationsPosition,
				});
			}
		}
	};

	const onLogin = (e: any) => {
		e.preventDefault();
		signOut();
	};

	// efecto para sobreescribir el background del login con el general
	useAddAppLoginClass();

	let codeEditorOptions = {
		stylingMode: 'outlined',
		placeholder: 'Ingresá el código que recibiste',
		mode: 'text',
		height: '50px',
		showClearButton: false,
		value: userInput,
		onValueChanged: handleCodeInput,
	};

	const maskEmail = (email: string) => `${email.slice(0, 2)}***@${email.split('@')[1]}`;
	const maskPhone = (phone: string) => `${phone.slice(0, 4)}***${phone.slice(-3)}`;

	const getDescriptionMessage = (main2fa: boolean) => {
		const maskedEmail = maskEmail(user!.email);
		const maskedPhone = maskPhone(user!.phone);
		return main2fa ? `Te enviamos un código al correo eletrónico ${maskedEmail}` : `Te enviamos un código por SMS al número ${maskedPhone}`;
	};
	
	let resendButtonText: any;
	if (showResendButton) {
		resendButtonText = (
			<Link to="#" onClick={handleResend2FaCode} className="resend-code-link-text">
				Volver a enviar código
			</Link>
		);
	} else if (attemptsLeft === 1) {
		resendButtonText = 'Te queda 1 intento';
	} else {
		resendButtonText = `Te quedan ${attemptsLeft} intentos`;
	}

	const get2FaOptions = (descriptionMessage: string) => (
		<div>
			<div className="description-message-step-1">{descriptionMessage}</div>
			<Form formData={formData.current} disabled={loading} showValidationSummary={false}>
				<Item editorType="dxNumberBox" editorOptions={codeEditorOptions}>
					<RequiredRule message="El código es requerido" />
					<Label visible={false} />
				</Item>
				<ButtonItem cssClass="item-login_animated login-item-buttom">
					<ButtonOptions width="100%" height="50px" type="default" useSubmitBehavior={true}>
						<span className="dx-button-text">{loading ? <LoadIndicator width="24px" height="24px" visible={true} /> : 'VERIFICAR'}</span>
					</ButtonOptions>
				</ButtonItem>
				<Item cssClass="resend-code-link">{resendButtonText}</Item>
			</Form>
		</div>
	);

	useEffect(() => {
		if (currentStep === 1 || currentStep === 2) {
			handleRequest2FaCode();
		}
	}, [currentStep, handleRequest2FaCode]);

	if (currentStep === 4) {
		return (
			<form className="sucess-content" onSubmit={onLogin}>
				<div className="sucess-content box ">
					<i className="mdi mdi-human-handsup"></i>
					<div className="title">¡Todo listo!</div>
					<div>Tu cuenta está lista para usarse.</div>
				</div>
				<Form>
					<ButtonItem cssClass={'item-login_animated login-item-buttom '}>
						<ButtonOptions width={'100%'} height={'50px'} type={'default'} useSubmitBehavior={true}>
							<span className="dx-button-text">{loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'INGRESAR'}</span>
						</ButtonOptions>
					</ButtonItem>
				</Form>
			</form>
		);
	} else {
		return (
			<form className="first-login-form" onSubmit={onSubmit}>
				<div className="title">¡Hola {user!.fullName.trim()}!</div>
				<div className="description-message-step-1">Terminemos de configurar tu cuenta</div>
				<div className="steps-box">
					Paso <strong>{currentStep}</strong> de <strong>3</strong>
				</div>
				{currentStep === 1 || currentStep === 2 ? (
					get2FaOptions(getDescriptionMessage(currentStep === 2))
				) : (
					<div>
						<div className="description-message-step-2">Elegí una nueva contraseña</div>
						<div className="password-instructions">
							<ul className="instructions-list">
								<li>Más de 8 y menos de 30 caractéres</li>
								<li>Al menos un número</li>
								<li>Al menos una letra en mayúsculas y una en minúsculas</li>
								<li>Al menos uno de estos símbolos @$!%*?&#-</li>
							</ul>
						</div>
						<Form formData={formDataStep3.current} disabled={loading} showValidationSummary={false}>
							<Item
								dataField="password"
								editorType="dxTextBox"
								editorOptions={passwordEditorOptions}
								cssClass="item-login_animated textbox-login-label"
							>
								<RequiredRule message="La contraseña es requerida" />
								<PatternRule pattern={pswPattern} message="La contraseña debe cumplir con los requisitos" />
								<Label visible={false} text="Nueva contraseña" />
							</Item>
							<Item
								dataField="confirmPassword"
								editorType="dxTextBox"
								editorOptions={confirmPasswordEditorOptions}
								cssClass="item-login_animated textbox-login-label"
							>
								<RequiredRule message="La contraseña es requerida" />
								<PatternRule pattern={pswPattern} message="La contraseña debe cumplir con los requisitos" />
								<Label visible={false} text="Repetir nueva contraseña" />
							</Item>
							<ButtonItem cssClass="item-login_animated login-item-buttom">
								<ButtonOptions width="100%" height="50px" type="default" useSubmitBehavior={true}>
									<span className="dx-button-text">
										{loading ? <LoadIndicator width="24px" height="24px" visible={true} /> : 'CAMBIAR CONTRASEÑA'}
									</span>
								</ButtonOptions>
							</ButtonItem>
						</Form>
					</div>
				)}
			</form>
		);
	}
}
