import axios from 'axios';
import { getRealmHeaders, getUserHeaders } from './config';

export async function getGlobalRoles() {
	try {
		const response = await axios.get('getglobalroles', {
			headers: {
				...getRealmHeaders(),
				...getUserHeaders(),
			},
		});
		return response.data.value;
	} catch(error) {
		let errorMessage = 'No se pudieron recuperar los roles.';
		if (axios.isAxiosError(error)) {
			if (error.response?.data.message) errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}
